<template>
  <div>
      <Header />
      <div class="dash-overflow"></div>
      <div class="dashboard">
        <Sidebar :reports="false" :addWeb="true" :webButton="true" />
        <div class="dash-content">

          <div>
            <ul class="breadcrumb">
              <li><a href="/">Websites</a></li>
              <li>{{ website }}</li>
            </ul>
          </div>

          <div class="web-list-block">
            <h1>Website Settings</h1>
          </div>

          <div class="dash-inner-content">

            <h3>Site information</h3>

            <form action="">
              <div class="form-group" style="margin-bottom: 48px; margin-top: 20px;">
                <label class="raz">
                  <input type="text" placeholder="&nbsp;" :value="website" readonly>
                  <span>Website address</span>
                </label>

              </div>
            </form>

            <h3>Tracking code</h3>

            <div class="copied-block" style="margin-top: 20px">
              <code id="sample">
                  &lt;!-- Polygraph fraud detection --&gt;<br>
                  &lt;script src="https://cdn.polygraph.net/pg.js"&gt;&lt;/script&gt;<br>
                  &lt;!-- /Polygraph fraud detection --&gt;
              </code>
              <a href="#" @click="copyToClipboard('sample');return false;" class="copy-btn"><img
                src="/img/dashboard/document-copy.svg" alt="">{{ copy }}</a>
            </div>

            <p style="white-space: nowrap">Please ensure the ads for {{ website }} are using the UTM parameters utm_campaign,
              utm_term, and utm_source.</p>

          </div>

        </div>

      </div>
  </div>
</template>

<script>
import Header from '@/components/dashboard/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';
import $ from 'jquery';

export default {
    name: 'WebsiteSettings',
    data() {
        return {
            copy: 'Copy Code',
            error: '',
            ready: false,
            websites: null,
        }
    },
    components: {
            Header,
            Sidebar
    },
    props: ['website'],
    methods: {
        copyToClipboard(id) {
          var r = document.createRange();
          r.selectNode(document.getElementById(id));
          window.getSelection().removeAllRanges();
          window.getSelection().addRange(r);
          document.execCommand('copy');
          window.getSelection().removeAllRanges();

          this.copy = 'Copied';
        }
    },
    mounted() {

        $( document ).ready(function() {

          $('.simple-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.header-inner .right').toggleClass('open');
            $('body').toggleClass('overflow');
          });

          $('.dash-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.dash-nav').toggleClass('open');
            $('body').toggleClass('overflow');
            $('.dash-overflow').toggleClass('visible');
          });

          $('.dash-overflow').on('click', function(){
            $('.dash-hamburger').removeClass('is-active');
            $('.dash-nav').removeClass('open');
            $('body').removeClass('overflow');
            $(this).removeClass('visible');
          });

          closeContentBlock();
          openLoginMenu();
          calcMainContentMargin();
          periodSelection();
          calculateContentMarginTop();
          addEventListenerForMenu();
        });

        function closeContentBlock() {
          const closeButtons = document.getElementsByClassName("close");

          for(let i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener("click", function (){
              closeButtons[i].parentElement.style.display = "none";
            });
          }
        }

        function openLoginMenu() {
          const accountMenu = document.getElementById("account-menu");
          const accountMenuPopup = document.getElementById("account-menu-popup");

          if (null !== accountMenu && null !== accountMenuPopup) {
            const toggleMenu = function() {
              accountMenuPopup.classList.toggle("hide-element");
            }

            accountMenu.addEventListener("click", function (e) {
              e.stopPropagation();
              toggleMenu();
            });

            document.addEventListener("click", function(e){
              const target = e.target;
              const itsAccountMenuPopup = target === accountMenuPopup || accountMenuPopup.contains(target);
              const itsAccountMenu = target === accountMenu;
              const menuIsActive = !accountMenuPopup.classList.contains("hide-element");

              if (!itsAccountMenuPopup && !itsAccountMenu && menuIsActive) {
                toggleMenu();
              }
            });
          }

        }

        function toggleDropdown() {
          $('.dropdown').on('click', function(){
            $('.dropdown-active + ul').slideToggle()
            let content = $('.dropdown-active + ul li.active a').text()
            console.log(content)
            $('.dropdown-active').html(content)
          })
        }
        toggleDropdown()

        function calcMainContentMargin() {
          const navigationBlock = document.getElementById("dash-navigation");
          const contentBlock = document.getElementsByClassName("dash-content");
          const footerBlock = document.getElementsByTagName("footer");

          if (
              null !== navigationBlock &&
              null !== contentBlock &&
              null !== footerBlock
          ) {
            const navigationBlockWidth = navigationBlock.offsetWidth;

            for(let i = 0; i < contentBlock.length; i++) {
              contentBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }

            for(let i = 0; i < footerBlock.length; i++) {
              footerBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }
          }

        }

        function periodSelection() {
          const allSelectionsMenu = document.getElementsByClassName("period-selection");

          for(let i = 0; i < allSelectionsMenu.length; i++) {

            const selectionsMenuLi =
                allSelectionsMenu[i].getElementsByTagName("ul")[0].getElementsByTagName("li");

            for(let i = 0; i < selectionsMenuLi.length; i++) {
              let anchor = selectionsMenuLi[i];
              anchor.onclick = function () {
                for (let i = 0; i < selectionsMenuLi.length; i++) {
                  selectionsMenuLi[i].classList.remove("active");
                }
                anchor.classList.add("active");
              }
            }

          }
        }

        function calculateContentMarginTop() {
          const headerHeight = $('header').height();
          $('.blog').css('margin-top', headerHeight + 'px');
          $('.dashboard').css('margin-top', headerHeight + 'px');
          $('.single').css('margin-top', headerHeight + 'px');
        }
        window.onresize = calculateContentMarginTop;

        function addEventListenerForMenu() {
          $('.main-pages-right').children('nav').children('ul').children('li').each(function () {
            $(this).on('click', function () {
              if ($('#homepage_hamburger_menu').css('display') === 'block') {
                $('.simple-hamburger').toggleClass('is-active');
                $('.header-inner .right').toggleClass('open');
                $('body').toggleClass('overflow');
              }
            });
          });
        }
    }
}
</script>
