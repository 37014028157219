<template>

  <div>
      <Header />
      <div class="dash-overflow"></div>
      <div class="dashboard dashboard-keywords">
        <Sidebar :reports="false" :addWeb="true" :webButton="true" :active="'websitesButton'" />
        <div class="dash-content">
          <div class="steps-block">
            <div class="step passed-step">
              <div class="circle-block">
                <div class="circle">1</div>
              </div>
              <div class="step-ttl">Add a website</div>
            </div>
            <div class="line"></div>
            <div class="step current-step">
              <div class="circle-block">
                <div class="circle">2</div>
              </div>
              <div class="step-ttl">Insert code</div>
            </div>
          </div>
          <div class="dash-inner-content">
            <h1>Tracking code</h1>
            <p>To start detecting click fraud, you need to add the following HTML code to the <code>&#60;head&#62;</code>
              section of {{ website }}:</p>
            <div class="copied-block" style="margin-top: 20px">
              <code id="sample">
                &lt;!-- Polygraph fraud detection --&gt;<br>
                &lt;script src="https://cdn.polygraph.net/pg.js"&gt;&lt;/script&gt;<br>
                &lt;!-- /Polygraph fraud detection --&gt;
              </code>
              <a href="#" @click="copyToClipboard('sample');return false;" class="copy-btn"><img
                src="/img/dashboard/document-copy.svg" alt="">{{ copy }}</a>
            </div>
            <p style="white-space: nowrap">Please note Polygraph requires your ads use the UTM parameters utm_campaign,
              utm_term, and utm_source.</p>
            <p>Learn more: <a href="https://polygraph.net/articles/what-are-UTMs-and-why-should-they-be-used.html">What are UTMs and why should they be used?</a></p>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Header from '@/components/dashboard/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';
import $ from 'jquery';

export default {
    name: 'AddCode',
    data() {
        return {
            website: null,
            copy: 'Copy Code',
            error: null
        }
    },
    components: {
        Header,
        Sidebar,
    },
    async created() {
        if (this.$route.params.website) {
            this.website = this.$route.params.website;

            // send the code in an e-mail
            try {
                await this.$http.get('dashboard/website/' + this.website + '/code');
            } catch(error) {
                this.error = error;
            }

        } else {

            // user reloaded the page or came here directly
            // get the website from the database
            // this is used within the page's text and not in the sidebar
            let result = null;

            try {
                result = await this.$http.get('dashboard/website/first');
                this.website = result.data.result.website;
            } catch(error) {
                this.error = error;
            }
        }
    },
    methods: {
        async logout(evt) {
            evt.preventDefault();

            try {
                await this.$store.dispatch('logout');
                this.$router.push({ name: 'Login'});
            } catch(error) {
                this.error = error;
            }
        },
        copyToClipboard(id) {
          var r = document.createRange();
          r.selectNode(document.getElementById(id));
          window.getSelection().removeAllRanges();
          window.getSelection().addRange(r);
          document.execCommand('copy');
          window.getSelection().removeAllRanges();

          this.copy = 'Copied';
        }
    },
    mounted() {

        $( document ).ready(function() {

          $('.simple-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.header-inner .right').toggleClass('open');
            $('body').toggleClass('overflow');
          });

          $('.dash-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.dash-nav').toggleClass('open');
            $('body').toggleClass('overflow');
            $('.dash-overflow').toggleClass('visible');
          });

          $('.dash-overflow').on('click', function(){
            $('.dash-hamburger').removeClass('is-active');
            $('.dash-nav').removeClass('open');
            $('body').removeClass('overflow');
            $(this).removeClass('visible');
          });

          closeContentBlock();
          openLoginMenu();
          calcMainContentMargin();
          periodSelection();
          calculateContentMarginTop();
          addEventListenerForMenu();
        });

        function closeContentBlock() {
          const closeButtons = document.getElementsByClassName("close");

          for(let i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener("click", function (){
              closeButtons[i].parentElement.style.display = "none";
            });
          }
        }

        function openLoginMenu() {
          const accountMenu = document.getElementById("account-menu");
          const accountMenuPopup = document.getElementById("account-menu-popup");

          if (null !== accountMenu && null !== accountMenuPopup) {
            const toggleMenu = function() {
              accountMenuPopup.classList.toggle("hide-element");
            }

            accountMenu.addEventListener("click", function (e) {
              e.stopPropagation();
              toggleMenu();
            });

            document.addEventListener("click", function(e){
              const target = e.target;
              const itsAccountMenuPopup = target === accountMenuPopup || accountMenuPopup.contains(target);
              const itsAccountMenu = target === accountMenu;
              const menuIsActive = !accountMenuPopup.classList.contains("hide-element");

              if (!itsAccountMenuPopup && !itsAccountMenu && menuIsActive) {
                toggleMenu();
              }
            });
          }

        }

        function toggleDropdown() {
          $('.dropdown').on('click', function(){
            $('.dropdown-active + ul').slideToggle()
            let content = $('.dropdown-active + ul li.active a').text()
            $('.dropdown-active').html(content)
          })
        }
        toggleDropdown()

        function calcMainContentMargin() {
          const navigationBlock = document.getElementById("dash-navigation");
          const contentBlock = document.getElementsByClassName("dash-content");
          const footerBlock = document.getElementsByTagName("footer");

          if (
              null !== navigationBlock &&
              null !== contentBlock &&
              null !== footerBlock
          ) {
            const navigationBlockWidth = navigationBlock.offsetWidth;

            for(let i = 0; i < contentBlock.length; i++) {
              contentBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }

            for(let i = 0; i < footerBlock.length; i++) {
              footerBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }
          }

        }

        function periodSelection() {
          const allSelectionsMenu = document.getElementsByClassName("period-selection");

          for(let i = 0; i < allSelectionsMenu.length; i++) {

            const selectionsMenuLi =
                allSelectionsMenu[i].getElementsByTagName("ul")[0].getElementsByTagName("li");

            for(let i = 0; i < selectionsMenuLi.length; i++) {
              let anchor = selectionsMenuLi[i];
              anchor.onclick = function () {
                for (let i = 0; i < selectionsMenuLi.length; i++) {
                  selectionsMenuLi[i].classList.remove("active");
                }
                anchor.classList.add("active");
              }
            }

          }
        }

        function calculateContentMarginTop() {
          const headerHeight = $('header').height();
          $('.blog').css('margin-top', headerHeight + 'px');
          $('.dashboard').css('margin-top', headerHeight + 'px');
          $('.single').css('margin-top', headerHeight + 'px');
        }
        window.onresize = calculateContentMarginTop;

        function addEventListenerForMenu() {
          $('.main-pages-right').children('nav').children('ul').children('li').each(function () {
            $(this).on('click', function () {
              if ($('#homepage_hamburger_menu').css('display') === 'block') {
                $('.simple-hamburger').toggleClass('is-active');
                $('.header-inner .right').toggleClass('open');
                $('body').toggleClass('overflow');
              }
            });
          });
        }
    }
}
</script>
