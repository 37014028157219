<template>
  <div>

    <div class="modal delete-modal" v-if="!!deleteModalOpen">
      <div class="modal-content">
        <div class="modal-text">
          To confirm you wish to delete this website and all its corresponding data, type "Yes" in the box below and click
          "Delete".
        </div>
        <form action="">
          <div class="form-group">
            <label class="raz">
              <input type="text" v-model="localDel" id="submit-input" @input="handleModalInputChange">
              <span>Decision</span>
            </label>
          </div>
          <div class="form-group" style="margin-bottom: 0px">
            <button type="button" id="submit-button" class="management-button" v-bind:class="{ 'disabled-button': delIsDisabled }" :disabled="delIsDisabled" @click="handleModalButtonClick(true)">Delete</button>
            <button type="button" class="management-button" @click="handleModalClose(true)">Cancel</button>
          </div>
        </form>
      </div>
    </div>

    <div class="modal disable-modal" v-if="!!disableModalOpen">
      <div class="modal-content">
        <div class="modal-text">
          To confirm you wish to disable this website and all its corresponding data, type "Yes" in the box below and click
          "Disable"
        </div>
        <form action="">
          <div class="form-group">
            <label class="raz">
              <input type="text" v-model="localDis" id="submit-input" @input="handleModalInputChange">
              <span>Decision</span>
            </label>
          </div>
          <div class="form-group" style="margin-bottom: 0px">
            <button type="button" id="submit-button" class="management-button" v-bind:class="{ 'disabled-button': disIsDisabled }" :disabled="disIsDisabled" @click="handleModalButtonClick(false)">Disable</button>
            <button type="button" class="management-button" @click="handleModalClose(false)">Cancel</button>
          </div>
        </form>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Modal',
  data() {
      return {
          localDel: null,
          localDis: null,
          delIsDisabled: true,
          disIsDisabled: true,
      }
  },
  props: [
      'del',
      'dis',
      'deleteModalOpen',
      'disableModalOpen',
      'deletedWebsite',
      'disabledWebsite',
      'updateSidebar',
      'websites'
  ],
  methods: {
      handleModalInputChange: function() {
          if (this.localDel === "yes" || this.localDel === "Yes") {
              this.delIsDisabled = false;
          } else {
              this.delIsDisabled = true;
          }

          if (this.localDis === "yes" || this.localDis === "Yes") {
              this.disIsDisabled = false;
              let website = this.websites.find(w => w.domain == this.disabledWebsite);
              website.active = 0;
          } else {
              this.disIsDisabled = true;
          }
      },
      handleModalClose: function (isDelete = false) {
        if (isDelete) {
          this.$emit('deleteModalOpen', false);
          this.localDel = null;
        } else {
            let website = this.websites.find(w => w.domain == this.disabledWebsite);
            website.active = 1;
          this.$emit('disableModalOpen', false);
          this.localDis = null;
        }
      },
      handleModalButtonClick: async function(isDelete = false) {
        if (isDelete) {
          this.$emit('deleteModalOpen', false);
          try {
              await this.$http.get('/dashboard/website/' + this.deletedWebsite + '/delete');
              this.$emit('updateSidebar', this.updateSidebar + 1);
              for (let i=0; i < this.websites.length; i++) {
                  if (this.websites[i].domain === this.deletedWebsite) {
                      this.$emit('websites', i);
                      break;
                  }
              }
              if (this.websites.length == 0)
                  return this.$router.push({
                      name: 'AddFirstWebsite'
                  });
              this.localDel = null;
          } catch(error) {
              this.error = error;
          }
        } else {
          // disable
          this.$emit('disableModalOpen', false);
          try {
              await this.$http.get('/dashboard/website/' + this.disabledWebsite + '/deactivate');
              this.$emit('updateSidebar', this.updateSidebar + 1);
              this.localDis = null;
          } catch(error) {
              this.error = error;
          }
        }
      }
  },
  watch: {
      dis(val) {
          this.localDis = val;
      },
      del(val) {
          this.localDel = val;
      }
  }
}
</script>
