<template>
  <div>
    <Header />
    <div class="dash-overflow"></div>
    <div class="dashboard">
      <Sidebar :reports="true" :addWeb="true" :webButtonActive="false" :googleButtonActive="false" :website="website" />
      <div class="dash-content">
        <h1 class="ttl">Refunds</h1>
      <div class="desc" style="padding-bottom: 48px;">The table below contains the details of every fraudulent click {{ website }} has received. You can use this data to request refunds from the ad platforms you are using.
      </div>

      <div class="dash-inner-content">

        <div class="mob flex-center white-text mobile-filter">
          <div class="flex-center">
            <div>Sources:</div>
            <div class="filter-dropdown-menu">
                <form v-if="sources">
                    <select v-model="selected">
                        <option v-for="source in sources" :key="source.utm_source" :value="source.utm_source">{{ source.utm_source }}</option>
                      </select>
                </form>
            </div>
          </div>

          <div class="period-selection dropdown">
            <span class="dropdown-active">This month</span>
            <ul style="display: none;">
                <li @click="updateTable(0)"><span>This month</span></li>
                <li @click="updateTable(1)"><span>{{ getMonth(1) }}</span></li>
                <li @click="updateTable(2)"><span>{{ getMonth(2) }}</span></li>
                <li @click="updateTable(3)"><span>{{ getMonth(3) }}</span></li>
                <li @click="updateTable(4)"><span>{{ getMonth(4) }}</span></li>
                <li @click="updateTable(5)"><span>{{ getMonth(5) }}</span></li>
                <li @click="updateTable(6)"><span>Past 6 months</span></li>
            </ul>
          </div>


        </div>

        <div class="overflow-x-auto">

          <div class="flex-center white-text m-width-1070 desktop-filter">
              <div class="period-selection">
                <ul>
                    <li @click="updateTable(0)" class="active">This month</li>
                    <li @click="updateTable(1)"><span>{{ getMonth(1) }}</span></li>
                    <li @click="updateTable(2)"><span>{{ getMonth(2) }}</span></li>
                    <li @click="updateTable(3)"><span>{{ getMonth(3) }}</span></li>
                    <li @click="updateTable(4)"><span>{{ getMonth(4) }}</span></li>
                    <li @click="updateTable(5)"><span>{{ getMonth(5) }}</span></li>
                    <li @click="updateTable(6)"><span>Past 6 months</span></li>
                </ul>
              </div>

            <div class="flex-start">
              <div>Source:</div>
              <div class="filter-dropdown-menu">
                  <form v-if="sources">
                      <select v-model="selected">
                          <option v-for="source in sources" :key="source.utm_source" :value="source.utm_source">{{ source.utm_source }}</option>
                        </select>
                    </form>
              </div>
            </div>

            <JsonCSV v-if="frauds" :data="frauds">
                  <div class="margin-left-8 download-csv-btn">Download CSV</div>
              </JsonCSV>

          </div>

          <div v-if="!ready" class="refunds-table m-width-1070">
              <div class="heading">
                <div class="label-six-columns">Date/Time</div>
                <div class="label-six-columns">IP Address</div>
                <div class="label-six-columns">Source</div>
                <div class="label-six-columns">Campaign</div>
                <div class="label-six-columns">Keyword</div>
                <div class="label-six-columns">Reason</div>
              </div>

              <div class="table-body">
                <div class="b-six-columns">Loading...</div>
              </div>
          </div>

          <div v-if="ready && frauds && frauds.length" class="refunds-table m-width-1070">

            <div class="heading">
              <div class="label-six-columns">Date/Time</div>
              <div class="label-six-columns">IP Address</div>
              <div class="label-six-columns">Source</div>
              <div class="label-six-columns">Campaign</div>
              <div class="label-six-columns">Keyword</div>
              <div class="label-six-columns">Reason</div>
            </div>

            <div v-for="fraud in frauds" :key="fraud.date" class="table-body">
              <div class="b-six-columns">{{ fraud.visited_at }}</div>
              <!--div class="b-six-columns"><span style="color: transparent; text-shadow: 0 0 5px white;">{{ fraud.ip }}</span></div-->
              <div class="b-six-columns">{{ fraud.ip }}</div>
              <div class="b-six-columns">{{ fraud.utm_source }}</div>
              <!--div class="b-six-columns"><span style="color: transparent; text-shadow: 0 0 5px white;">{{ fraud.utm_campaign }}</span></div-->
              <div class="b-six-columns">{{ fraud.utm_campaign }}</div>
              <div class="b-six-columns">{{ fraud.utm_term }}</div>
              <div class="b-six-columns">{{ fraud.reason }}</div>
            </div>
          </div>

          <div v-if="ready && (!frauds || !frauds.length)" class="refunds-table m-width-1070">
              <div class="heading">
                <div class="label-six-columns">Date/Time</div>
                <div class="label-six-columns">IP Address</div>
                <div class="label-six-columns">Source</div>
                <div class="label-six-columns">Campaign</div>
                <div class="label-six-columns">Keyword</div>
                <div class="label-six-columns">Reason</div>
              </div>

              <div class="table-body">
                <div class="b-six-columns">No data.</div>
              </div>
          </div>

        </div>

      </div>


    </div>

  </div>

  </div>
</template>

<script>
import Header from '@/components/dashboard/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';
import $ from 'jquery';

export default {
    name: 'Refunds',
    data() {
        return {
            ready: false,
            campaigns: [],
            selectedCampaigns: [],
            error: null,
            showModal: false,
            frauds: null,
            sources: null,
            month: 0,
            selected: 'All'
        }
    },
    props: ['website'],
    components: {
            Header,
            Sidebar
    },
    async created() {
        // make sure he is authorised to view this website
        try {
            await this.$http.get('/dashboard/website/' + this.website + '/verify');
        } catch(error) {
            try {
                await this.$store.dispatch('logout');
                this.$router.push({ name: 'Login'});
            } catch(error) {
                // do nothing
            }
        }

        this.getSources();
        this.getFraud(0);
    },
    methods: {
        async getSources() {
            try {
                let response = await this.$http.get('/dashboard/website/' + this.website + '/sources-list');
                this.sources = response.data.sources;

            } catch(error) {
                this.error = error;
            }
        },
        getMonth(val) {
            const current = new Date();
            current.setMonth(current.getMonth()-val);

            // deal with leap year
            if (current.toLocaleString('default', { month: 'long' }) == new Date().toLocaleString("default", { month: "long" })) {
                // leap year
                current.setMonth(current.getMonth()-1);
                return current.toLocaleString('default', { month: 'long' });
            } else {
                return current.toLocaleString('default', { month: 'long' });
            }
        },
        updateTable(val) {
            this.frauds = null;
            this.getFraud(val);
        },
        async getFraud(month) {

            this.ready = false;

            this.month = month;

            // get its fraud
            try {
                let response = await this.$http.get('/dashboard/website/' + this.website + '/fraud?month=' + month + '&source=' + this.selected);
                this.frauds = response.data.result;

                if (!Object.keys(response.data.frauds).length) {
                    // no fraud
                    // check if new account
                    let response = await this.$http.get('/dashboard/account');
                    if (response.data.result == true) {
                        this.newAccount = true;
                    } else {
                        this.utmWarning = true;
                    }

                    this.ready = true;

                } else {
                    this.frauds = response.data;
                    this.ready = true;
                }

            } catch(error) {
                this.error = error;
                this.ready = true;
            }


        },
        confirmDelete(evt) {

            evt.preventDefault();

            this.showModal = true;
        },
        async doDelete() {

            try {
                await this.$http.get('/dashboard/website/' + this.website + '/delete');

                return this.$router.push({
                    name: 'Dashboard',
                });

            } catch(error) {
                this.error = error;
            }

        },
        async orderBy(evt, val) {

          evt.preventDefault();

          try {
              let response = await this.$http.get('/dashboard/website/' + this.website + '/campaigns?sort=' + val);
              this.campaigns = response.data.result;
          } catch(error) {
              this.error = error;
          }

        },

        async deleteCampaigns(evt) {

          evt.preventDefault();

          try {
              await this.$http.post('/dashboard/website/' + this.website + '/campaigns/delete', { campaigns: this.selectedCampaigns });

              let temp = this.campaigns.filter(campaign => {
                return !(this.selectedCampaigns.includes(campaign.utm_campaign));
              });

              this.campaigns = temp;

              this.selectedCampaigns = [];

          } catch(error) {
              this.error = error;
          }

        }

    },
    watch: {
        selected() {
            this.getFraud(this.month);
        }
    },
    mounted() {

        $( document ).ready(function() {

          $('.simple-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.header-inner .right').toggleClass('open');
            $('body').toggleClass('overflow');
          });

          $('.dash-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.dash-nav').toggleClass('open');
            $('body').toggleClass('overflow');
            $('.dash-overflow').toggleClass('visible');
            $('.dash-reports').toggleClass('open');
          });

          $('.dash-overflow').on('click', function(){
            $('.dash-hamburger').removeClass('is-active');
            $('.dash-nav').removeClass('open');
            $('body').removeClass('overflow');
            $(this).removeClass('visible');
            $('.dash-reports').removeClass('open');
          });

          closeContentBlock();
          openLoginMenu();
          calcMainContentMargin();
          periodSelection();
          calculateContentMarginTop();
          addEventListenerForMenu();
        });

        function closeContentBlock() {
          const closeButtons = document.getElementsByClassName("close");

          for(let i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener("click", function (){
              closeButtons[i].parentElement.style.display = "none";
            });
          }
        }

        function openLoginMenu() {
          const accountMenu = document.getElementById("account-menu");
          const accountMenuPopup = document.getElementById("account-menu-popup");

          if (null !== accountMenu && null !== accountMenuPopup) {
            const toggleMenu = function() {
              accountMenuPopup.classList.toggle("hide-element");
            }

            accountMenu.addEventListener("click", function (e) {
              e.stopPropagation();
              toggleMenu();
            });

            document.addEventListener("click", function(e){
              const target = e.target;
              const itsAccountMenuPopup = target === accountMenuPopup || accountMenuPopup.contains(target);
              const itsAccountMenu = target === accountMenu;
              const menuIsActive = !accountMenuPopup.classList.contains("hide-element");

              if (!itsAccountMenuPopup && !itsAccountMenu && menuIsActive) {
                toggleMenu();
              }
            });
          }

        }

        function toggleDropdown() {
            $('.dropdown').on('click', function(){
                let content = $('.dropdown-active + ul li.active span').text()
                $('.dropdown-active + ul').slideToggle()
                if (content) {
                    $('.dropdown-active').html(content)
                }
            })
        }
        toggleDropdown()

        function calcMainContentMargin() {
          const navigationBlock = document.getElementById("dash-navigation");
          const contentBlock = document.getElementsByClassName("dash-content");
          const footerBlock = document.getElementsByTagName("footer");

          if (
              null !== navigationBlock &&
              null !== contentBlock &&
              null !== footerBlock
          ) {
            const navigationBlockWidth = navigationBlock.offsetWidth;

            for(let i = 0; i < contentBlock.length; i++) {
              contentBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }

            for(let i = 0; i < footerBlock.length; i++) {
              footerBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }
          }

        }

        function periodSelection() {
          const allSelectionsMenu = document.getElementsByClassName("period-selection");

          for(let i = 0; i < allSelectionsMenu.length; i++) {

            const selectionsMenuLi =
                allSelectionsMenu[i].getElementsByTagName("ul")[0].getElementsByTagName("li");

            for(let i = 0; i < selectionsMenuLi.length; i++) {
              let anchor = selectionsMenuLi[i];
              anchor.onclick = function () {
                for (let i = 0; i < selectionsMenuLi.length; i++) {
                  selectionsMenuLi[i].classList.remove("active");
                }
                anchor.classList.add("active");
              }
            }

          }
        }

        function calculateContentMarginTop() {
          const headerHeight = $('header').height();
          $('.blog').css('margin-top', headerHeight + 'px');
          $('.dashboard').css('margin-top', headerHeight + 'px');
          $('.single').css('margin-top', headerHeight + 'px');
        }
        window.onresize = calculateContentMarginTop;

        function addEventListenerForMenu() {
          $('.main-pages-right').children('nav').children('ul').children('li').each(function () {
            $(this).on('click', function () {
              if ($('#homepage_hamburger_menu').css('display') === 'block') {
                $('.simple-hamburger').toggleClass('is-active');
                $('.header-inner .right').toggleClass('open');
                $('body').toggleClass('overflow');
              }
            });
          });
        }

    }
}
</script>

<style scoped>
.web-list-block .add-site-btn, .dash-content .download-csv-btn {
    cursor: pointer;
}

</style>
