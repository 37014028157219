<template>
  <div>
      <Header />
      <div class="dash-overflow"></div>

      <div class="dashboard">
        <Sidebar :reports="false" :addWeb="true" :webButton="true" />
        <div class="dash-content">
          <div>
            <ul class="breadcrumb">
              <li><a href="/">Websites</a></li>
              <li>Add Site</li>
            </ul>
          </div>
          <div class="web-list-block">
            <h1>Add a website</h1>
          </div>
          <div class="dash-inner-content">

            <h3>Site information</h3>

            <form @submit="submitForm">
              <div class="form-group" style="margin-top: 24px;">
                <label class="raz input-field-with-hint">
                  <input v-model="website" type="text" maxlength="50" placeholder=" " @change="updateWebsite" />
                  <span>Domain name</span>
                  <p class="error" v-if="formErrors.websiteEmpty">Domain name is required.</p>
                  <p class="error" v-if="formErrors.websiteTooLong">Domain name be shorter than 50 characters.</p>
                  <p class="error" v-if="formErrors.websiteInvalid">Domain name must be in the form "website.com".</p>
                  <p class="error" v-if="error">{{ error }}</p>
                </label>
                <div class="hint-construction">
                  <img src="/img/dashboard/question-icon.svg" class="question-icon" alt="">
                  <div class="info-popup">
                    Domain of the website.<br>
                    For example, website.com
                  </div>
                </div>
              </div>


                <h1>Tracking code</h1>
                <p style="white-space: nowrap">To start detecting click fraud, you need to add the following HTML code to the <code>&#60;head&#62;</code>
                  section of {{ placeholder }}:</p>

                <div class="copied-block" style="margin-top: 20px">
                  <code id="sample">
                      &lt;!-- Polygraph fraud detection --&gt;<br>
                      &lt;script src="https://cdn.polygraph.net/pg.js"&gt;&lt;/script&gt;<br>
                      &lt;!-- /Polygraph fraud detection --&gt;
                  </code>
                  <a href="#" @click="copyToClipboard('sample');return false;" class="copy-btn"><img
                    src="/img/dashboard/document-copy.svg" alt="">{{ copy }}</a>
                </div>

                <p style="white-space: nowrap">Please note Polygraph requires your ads use the UTM parameters utm_campaign,
                  utm_term, and utm_source.</p>
                <p>Learn more: <a href="https://polygraph.net/articles/what-are-UTMs-and-why-should-they-be-used.html">What are UTMs and why should they be used?</a></p>

                <div class="form-group" style="margin-top: 20px">
                  <input type="submit" class="next-step" value="Submit">
                </div>

            </form>

          </div>

        </div>

      </div>
  </div>
</template>

<script>
import Header from '@/components/dashboard/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';
import $ from 'jquery';

export default {
    name: 'AddWebsite',
    data() {
        return {
            error: '',
            ready: false,
            websites: null,
            website: null,
            formErrors: {
                websiteEmpty: false,
                websiteTooLong: false,
                websiteInvalid: false
            },
            copy: 'Copy Code',
            placeholder: 'the website'
        }
    },
    components: {
            Header,
            Sidebar
    },
    methods: {
        updateWebsite() {
                this.placeholder = this.website;
        },
        async addWebsite() {

            try {
                await this.$http.post('/dashboard/website/create', {
                    website: this.website
                });

                return this.$router.push({
                    name: 'Websites'
                });

            } catch(error) {
                this.error = error.response.data.error;
            }

        },
        async submitForm(evt) {

            evt.preventDefault();

            this.clearErrors();

            this.formErrors = this.validateForm(this.website);

            // if formErrors is not empty
            if (Object.keys(this.formErrors).length) return;

            // everything ok
            // create payment method and confirm payment intent
            this.addWebsite();
        },
        clearErrors() {

            this.error = null;
            this.formErrors.websiteEmpty = false;
            this.formErrors.websiteTooLong = false;
            this.formErrors.websiteInvalid = false;

        },
        validateForm(website) {

            // check the entire form in one go rather than updating formErrors one by one
            const errors = {};

            if (!website)
              errors.websiteEmpty = true;

            if (website && website.length > 50)
              errors.websiteTooLong = true;

            if (website && !(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](\.[a-zA-Z]{2,})+$/.test(website)))
              errors.websiteInvalid = true;

            if (website && website.substring(0,4) == 'www.')
              errors.websiteInvalid = true;

            return errors;
        },
        copyToClipboard(id) {
          var r = document.createRange();
          r.selectNode(document.getElementById(id));
          window.getSelection().removeAllRanges();
          window.getSelection().addRange(r);
          document.execCommand('copy');
          window.getSelection().removeAllRanges();

          this.copy = 'Copied';
        }
    },
    mounted() {

        $( document ).ready(function() {

          $('.simple-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.header-inner .right').toggleClass('open');
            $('body').toggleClass('overflow');
          });

          $('.dash-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.dash-nav').toggleClass('open');
            $('body').toggleClass('overflow');
            $('.dash-overflow').toggleClass('visible');
          });

          $('.dash-overflow').on('click', function(){
            $('.dash-hamburger').removeClass('is-active');
            $('.dash-nav').removeClass('open');
            $('body').removeClass('overflow');
            $(this).removeClass('visible');
          });

          closeContentBlock();
          openLoginMenu();
          calcMainContentMargin();
          periodSelection();
          calculateContentMarginTop();
          addEventListenerForMenu();
        });

        function closeContentBlock() {
          const closeButtons = document.getElementsByClassName("close");

          for(let i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener("click", function (){
              closeButtons[i].parentElement.style.display = "none";
            });
          }
        }

        function openLoginMenu() {
          const accountMenu = document.getElementById("account-menu");
          const accountMenuPopup = document.getElementById("account-menu-popup");

          if (null !== accountMenu && null !== accountMenuPopup) {
            const toggleMenu = function() {
              accountMenuPopup.classList.toggle("hide-element");
            }

            accountMenu.addEventListener("click", function (e) {
              e.stopPropagation();
              toggleMenu();
            });

            document.addEventListener("click", function(e){
              const target = e.target;
              const itsAccountMenuPopup = target === accountMenuPopup || accountMenuPopup.contains(target);
              const itsAccountMenu = target === accountMenu;
              const menuIsActive = !accountMenuPopup.classList.contains("hide-element");

              if (!itsAccountMenuPopup && !itsAccountMenu && menuIsActive) {
                toggleMenu();
              }
            });
          }

        }

        function toggleDropdown() {
          $('.dropdown').on('click', function(){
            $('.dropdown-active + ul').slideToggle()
            let content = $('.dropdown-active + ul li.active a').text()
            console.log(content)
            $('.dropdown-active').html(content)
          })
        }
        toggleDropdown()

        function calcMainContentMargin() {
          const navigationBlock = document.getElementById("dash-navigation");
          const contentBlock = document.getElementsByClassName("dash-content");
          const footerBlock = document.getElementsByTagName("footer");

          if (
              null !== navigationBlock &&
              null !== contentBlock &&
              null !== footerBlock
          ) {
            const navigationBlockWidth = navigationBlock.offsetWidth;

            for(let i = 0; i < contentBlock.length; i++) {
              contentBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }

            for(let i = 0; i < footerBlock.length; i++) {
              footerBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }
          }

        }

        function periodSelection() {
          const allSelectionsMenu = document.getElementsByClassName("period-selection");

          for(let i = 0; i < allSelectionsMenu.length; i++) {

            const selectionsMenuLi =
                allSelectionsMenu[i].getElementsByTagName("ul")[0].getElementsByTagName("li");

            for(let i = 0; i < selectionsMenuLi.length; i++) {
              let anchor = selectionsMenuLi[i];
              anchor.onclick = function () {
                for (let i = 0; i < selectionsMenuLi.length; i++) {
                  selectionsMenuLi[i].classList.remove("active");
                }
                anchor.classList.add("active");
              }
            }

          }
        }

        function calculateContentMarginTop() {
          const headerHeight = $('header').height();
          $('.blog').css('margin-top', headerHeight + 'px');
          $('.dashboard').css('margin-top', headerHeight + 'px');
          $('.single').css('margin-top', headerHeight + 'px');
        }
        window.onresize = calculateContentMarginTop;

        function addEventListenerForMenu() {
          $('.main-pages-right').children('nav').children('ul').children('li').each(function () {
            $(this).on('click', function () {
              if ($('#homepage_hamburger_menu').css('display') === 'block') {
                $('.simple-hamburger').toggleClass('is-active');
                $('.header-inner .right').toggleClass('open');
                $('body').toggleClass('overflow');
              }
            });
          });
        }
    }
}
</script>
