<template>
  <div>
      <Header />
      <div class="dash-overflow"></div>
      <div class="dashboard dashboard-keywords">
        <Sidebar :reports="false" :addWeb="true" :googleButtonActive="true" :updateSidebar="updateSidebar" />
        <div v-show="ready" class="dash-content">
          <div class="dash-inner-content">
            <div v-if="!gaccounts.length" class="welcome-message">
              <h2>About Google Ads Integration</h2>
              <p>Polygraph monitors thousands of active bots and bot proxies in real time, and can automatically block these bots and bot proxies from seeing your Google ads.</p>
              <p>To enable this feature, click the button below to sign in to your Google account and give Polygraph permission to access and manage your Google Ads campaigns. (We only update the <a href="https://support.google.com/google-ads/answer/2456098?hl=en" target="_new">IP Exclusions lists</a> for your ad campaigns. Read more in our <a href="https://polygraph.net/privacy-policy.html#googleads">privacy policy</a>).</p>
              <p>After giving Polygraph permission to access your Google Ads account, our bot detection software will manage the blocking for you. You don't need to do anything else.</p>
            </div>
            <div class="g-list-block">
              <h1>Google Ads Integration</h1>
              <a :href="'https://api.polygraph.net/dashboard/google/new?code='+token"><img src="/img/dashboard/google.png" alt="Link Google Ads Account"></a>
              <div class="overflow-x-auto">
                <div v-if="gaccounts && gaccounts.length" class="web-table">
                  <div class="heading">
                    <div class="label-client">Client ID</div>
                    <div class="label-client">Account Name</div>
                    <div class="label-friendly">Friendly Description (Optional)</div>
                    <div class="label-disable">Block Bots &amp; Bot Proxies</div>
                  </div>
                  <div v-for="(account, index) in gaccounts" :key="account.client" class="table-body">
                    <div class="b-client">{{ account.client }}</div>
                    <div v-if="account.name" class="b-client">{{ account.name }}</div>
                    <div v-else class="b-client">-</div>
                    <div class="b-friendly">
                        <span v-show="editing != account.client" @click="toggleEdit(account.client)">{{ account.friendly }}</span>
                        <input :ref="account.client" type="text" maxlength="60" v-model="account.friendly" v-show="editing == account.client" @blur="saveEdit(account)" @keyup.enter="$event.target.blur()">
                    </div>
                    <div class="b-disable">
                      <input class="checkbox" :id="'checkbox'+index" type="checkbox" v-model="account.active" @change="handleDisableClick(account.client)">
                      <label class="toggle toggle-approved-site" :for="'checkbox'+index">
                        <span class="disc disc-approved-site"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div v-if="gaccounts.length" class="unlink">
                    <router-link :to="{ name: 'Unlink' }" class="unlink-link">Unlink Google Ads Account</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Header from '@/components/dashboard/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';
import $ from 'jquery';

export default {
    name: 'Dashboard',
    data() {
        return {
            error: '',
            ready: false,
            editing: null,
            gaccounts: [],
            clicks: null,
            del: null,
            dis: null,
            deleteModalOpen: false,
            disableModalOpen: false,
            disabledWebsite: null,
            deletedWebsite: null,
            updateSidebar: 0
        }
    },
    components: {
        Header,
        Sidebar
    },
    computed: {
        token() {
            return this.$store.getters.getToken;
        }
    },
    methods: {
        async linkAccount(evt) {

            evt.preventDefault();

            try {
                await this.$http.get('/dashboard/google/new');
            } catch(error) {
                this.error = error;
            }

        },
        async handleDeleteClick(client) {
            try {
                await this.$http.get('/dashboard/google/' + client + '/unlink');
                this.gaccounts = this.gaccounts.filter(el => el.client != client);
            } catch(error) {
                this.error = error;
            }
        },
        async handleDisableClick(client) {

            let account = this.gaccounts.find(el => el.client == client);

            try {
                await this.$http.post('/dashboard/google/activate', {
                    clientId: account.id,
                    active: account.active
                });
            } catch(error) {
                this.error = error.response.data.error;
            }
        },
        toggleEdit(client) {
            this.editing = client;
            let account = this.gaccounts.find(el => el.client == client);
            account.friendly = '';
            this.$nextTick(() => this.$refs[client][0].focus());
        },
        async saveEdit(account) {
            this.editing = null;
            try {
                await this.$http.post('/dashboard/google/friendly', {
                    clientId: account.id,
                    friendly: account.friendly
                });
            } catch(error) {
                this.error = error.response.data.error;
            }
        }
    },
    async created() {

        this.ready = false;

        if (this.$route.params.clicks) {
            this.clicks = this.$route.params.clicks;

            if ((this.clicks != "500") && (this.clicks != "10,000") && (this.clicks != "25,000") &&
                (this.clicks != "50,000") && (this.clicks != "75,000") && (this.clicks != "100,000")) {
                this.clicks = null;
            }
        }

        // even if first time logging in, need to double check he doesn't have an existing subscription
        // as he may be logging in again using the account activation e-mail

        // check his subscription
        try {
            let response = await this.$http.get('/billing/subscription/status');

            this.$store.dispatch('setPaid', response.data.result.paid == true);

            if (response.data.result.expired && this.clicks) {
                // no active subscription
                if (this.clicks == "500") {
                    let url = '/billing/subscription/create';

                    try {
                        await this.$http.post(url,
                            {
                                price_id: 'price_free',
                                product_id: 'prod_free',
                            }
                        );

                    } catch(error) {
                        this.error = error.response.data.error;
                    }

                } else {
                    return this.$router.push({
                        name: 'FirstSubscription',
                        params: { clicks: this.clicks }
                    });
                }

            } else if (response.data.result.expired) {
                // we don't know their clicks
                // this is because register -> e-mail -> close browser -> login, so we lost his clicks variable
                // need to fetch it from database

                try {
                    let response = await this.$http.get('/authentication/package');
                    this.clicks = response.data.clicks.clicks;

                    return this.$router.push({
                        name: 'FirstSubscription',
                        params: { clicks: this.clicks }
                    });

                } catch(error) {
                    this.error = error;
                }
            }
        } catch(error) {
            this.error = error;
        }

        // at this point his subscription is ok
        // either ask him to add a website or show the main dashboard

        // get his websites
        try {
            let response = await this.$http.get('/dashboard/websites');

            this.websites = response.data.result;

        } catch(error) {
            this.error = error;
        }

        if (!this.websites.length) {
            // if no websites
            return this.$router.push({
                name: 'AddFirstWebsite'
            });
        }

        // get clients
        // let url = this.baseurl + 'myaccounts';
        let accountsLinked = [];
        try {
            let response = await this.$http.get('/dashboard/google/accounts');
            accountsLinked = response.data.result;
        } catch(error) {
            this.error = error;
        }

        if (accountsLinked && !Array.isArray(accountsLinked) && accountsLinked.charAt(0) != '[') accountsLinked = JSON.parse(accountsLinked.substring(1));

        // get unlinked accounts
        // let url = this.baseurl + 'unlinked_accounts';
        let accountsUnlinked = [];
        try {
            let response = await this.$http.get('/dashboard/google/accounts-unlinked');
            accountsUnlinked = response.data.result;
        } catch(error) {
            this.error = error;
        }

        if (accountsUnlinked && !Array.isArray(accountsUnlinked) && accountsUnlinked.charAt(0) != '[') accountsUnlinked = JSON.parse(accountsUnlinked.substring(1));

        // create the clients array for the UI
        let clients = [];

        for (let i = 0; i < accountsLinked.length; i++) {

            if (!accountsLinked[i].friendly) accountsLinked[i].friendly = 'Type a friendly description here';

            clients.push(
                {
                    id: accountsLinked[i].id,
                    client: accountsLinked[i].client_id,
                    name: accountsLinked[i].account_name,
                    active: true,
                    friendly: accountsLinked[i].friendly
                }
            );
        }

        for (let i = 0; i < accountsUnlinked.length; i++) {

            if (!accountsUnlinked[i].friendly) accountsUnlinked[i].friendly = 'Type a friendly description here';

            clients.push(
                {
                    id: accountsUnlinked[i].id,
                    client: accountsUnlinked[i].client_id,
                    name: accountsUnlinked[i].account_name,
                    active: false,
                    friendly: accountsUnlinked[i].friendly
                }
            );
        }

        // finally, sort by client name desc
        clients.sort(function(a, b) {
            let textA = a.name.toUpperCase();
            let textB = b.name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        this.gaccounts = clients;

        this.ready = true;
    },
    mounted() {

        $( document ).ready(function() {

          $('.simple-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.header-inner .right').toggleClass('open');
            $('body').toggleClass('overflow');
          });

          $('.dash-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.dash-nav').toggleClass('open');
            $('body').toggleClass('overflow');
            $('.dash-overflow').toggleClass('visible');
          });

          $('.dash-overflow').on('click', function(){
            $('.dash-hamburger').removeClass('is-active');
            $('.dash-nav').removeClass('open');
            $('body').removeClass('overflow');
            $(this).removeClass('visible');
          });

          closeContentBlock();
          openLoginMenu();
          calcMainContentMargin();
          periodSelection();
          calculateContentMarginTop();
          addEventListenerForMenu();
        });

        function closeContentBlock() {
          const closeButtons = document.getElementsByClassName("close");

          for(let i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener("click", function (){
              closeButtons[i].parentElement.style.display = "none";
            });
          }
        }

        function openLoginMenu() {
          const accountMenu = document.getElementById("account-menu");
          const accountMenuPopup = document.getElementById("account-menu-popup");

          if (null !== accountMenu && null !== accountMenuPopup) {
            const toggleMenu = function() {
              accountMenuPopup.classList.toggle("hide-element");
            }

            accountMenu.addEventListener("click", function (e) {
              e.stopPropagation();
              toggleMenu();
            });

            document.addEventListener("click", function(e){
              const target = e.target;
              const itsAccountMenuPopup = target === accountMenuPopup || accountMenuPopup.contains(target);
              const itsAccountMenu = target === accountMenu;
              const menuIsActive = !accountMenuPopup.classList.contains("hide-element");

              if (!itsAccountMenuPopup && !itsAccountMenu && menuIsActive) {
                toggleMenu();
              }
            });
          }

        }

        function toggleDropdown() {
          $('.dropdown').on('click', function(){
            $('.dropdown-active + ul').slideToggle()
            let content = $('.dropdown-active + ul li.active a').text()
            console.log(content)
            $('.dropdown-active').html(content)
          })
        }
        toggleDropdown()

        function calcMainContentMargin() {
          const navigationBlock = document.getElementById("dash-navigation");
          const contentBlock = document.getElementsByClassName("dash-content");
          const footerBlock = document.getElementsByTagName("footer");

          if (
              null !== navigationBlock &&
              null !== contentBlock &&
              null !== footerBlock
          ) {
            const navigationBlockWidth = navigationBlock.offsetWidth;

            for(let i = 0; i < contentBlock.length; i++) {
              contentBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }

            for(let i = 0; i < footerBlock.length; i++) {
              footerBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }
          }

        }

        function periodSelection() {
          const allSelectionsMenu = document.getElementsByClassName("period-selection");

          for(let i = 0; i < allSelectionsMenu.length; i++) {

            const selectionsMenuLi =
                allSelectionsMenu[i].getElementsByTagName("ul")[0].getElementsByTagName("li");

            for(let i = 0; i < selectionsMenuLi.length; i++) {
              let anchor = selectionsMenuLi[i];
              anchor.onclick = function () {
                for (let i = 0; i < selectionsMenuLi.length; i++) {
                  selectionsMenuLi[i].classList.remove("active");
                }
                anchor.classList.add("active");
              }
            }

          }
        }

        function calculateContentMarginTop() {
          const headerHeight = $('header').height();
          $('.blog').css('margin-top', headerHeight + 'px');
          $('.dashboard').css('margin-top', headerHeight + 'px');
          $('.single').css('margin-top', headerHeight + 'px');
        }
        window.onresize = calculateContentMarginTop;

        function addEventListenerForMenu() {
          $('.main-pages-right').children('nav').children('ul').children('li').each(function () {
            $(this).on('click', function () {
              if ($('#homepage_hamburger_menu').css('display') === 'block') {
                $('.simple-hamburger').toggleClass('is-active');
                $('.header-inner .right').toggleClass('open');
                $('body').toggleClass('overflow');
              }
            });
          });
        }

    },
}
</script>

<style scoped>
.unlink {
    padding-top: 20px;
    padding-left: 16px;
}

.unlink-link {
    color: #fff;
    font-size: 14px;
}

</style>
