<template>
  <div>
      <Header />
      <div class="dash-overflow"></div>
      <div class="dashboard">
        <Sidebar :reports="false" :addWeb="hasSub" :webButtonActive="false" :googleButtonActive="false" :settingsButtonActive="true" />
        <div class="dash-content">
            <h1 class="ttl">Settings</h1>

            <div class="navigation-block">
              <div class="active">
                <router-link :to="{ name: 'Settings' }">Account</router-link>
              </div>
              <div>
                <router-link :to="{ name: 'Subscription' }">Subscription</router-link>
              </div>
            </div>

            <div v-if="ready" class="dash-inner-content">

              <div class="settings-block mb-32" style="padding:32px">
                <div class="settings-block-inner">

                  <div class="settings-block-title">Account Details</div>

                  <form class="settings-block-form">

                    <div class="settings-form-group padding-bottom-8">
                      <div class="settings-form-column">
                        <label for="first-name">Full Name</label>
                        <input v-model="form.fullName" type="text" maxlength="50" id="full-name" placeholder=" " />
                      </div>
                    </div>
                    <p class="error" v-if="formErrors.fullNameEmpty">Full Name is required.</p>
                    <p class="error" v-if="formErrors.fullNameTooLong">Full Name must be shorter than 50 characters.</p>

                    <div class="settings-form-group padding-top-bottom-8">
                      <div class="settings-form-column">
                        <label for="company-name">Company Name</label>
                        <input v-model="form.company" type="text" maxlength="50" id="company" placeholder=" " />
                      </div>
                    </div>
                    <p class="error" v-if="formErrors.companyTooLong">Company must be shorter than 50 characters.</p>

                    <div class="settings-form-group padding-top-bottom-8">
                      <div class="settings-form-column">
                        <label for="phone">Telephone Number</label>
                        <input v-model="form.telephone" type="text" maxlength="50" id="telephone" placeholder=" " />
                      </div>
                    </div>
                    <p class="error" v-if="formErrors.telephoneTooLong">Telephone must be shorter than 50 characters.</p>

                    <div class="settings-form-group padding-top-bottom-8">
                      <div class="settings-form-column instruction">
                        To change your e-mail address, please contact <span style="color: #03ADEE;">team@polygraph.net</span>.
                      </div>
                    </div>

                    <div class="settings-form-group" style ="padding-top:16px">
                      <div class="settings-form-column">
                        <a href="#" @click="processProfile" class="next-step">{{ profileButtonValue }}</a>
                      </div>
                    </div>

                  </form>

                </div>
              </div>

              <div class="settings-block change-data-block-content" style="padding:32px">
                <div class="settings-block-inner">

                  <div class="settings-block-title">Password</div>
                  <div class="settings-form-group padding-top-bottom-8">
                    <div class="settings-form-column instruction">
                      If you want to change your password, you can do so using the form below.
                    </div>
                  </div>

                  <form class="settings-block-form">

                    <div class="settings-form-group padding-top-bottom-8">
                      <div class="settings-form-column">
                        <label for="new-password">New Password</label>
                        <label for="" class="raz">
                          <input v-model="form.password" type="password" maxlength="50" id="password" placeholder=" " />
                          <i class="far fa-eye-slash" id="toggleNewPassword"></i>
                        </label>
                        <div class="hint padding-both-8">8+ characters, with min. one number and one uppercase letter.</div>
                      </div>
                    </div>
                    <p class="error" v-if="formErrors.passwordEmpty">Password cannot be blank.</p>
                    <p class="error" v-if="formErrors.passwordTooLong">Password must be shorter than 50 characters.</p>

                    <div class="settings-form-group" style="padding-top: 16px;">
                      <div class="settings-form-column flex-end">
                        <a href="#" @click="processPassword" class="next-step">{{ passwordButtonValue }}</a>
                      </div>
                    </div>

                  </form>

                </div>
              </div>


            </div>

            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/dashboard/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';
import $ from 'jquery';

export default {
    name: 'Settings',
    data() {
        return {
            form: {
                fullName: '',
                company: '',
                telephone: '',
                password: '',
            },
            formErrors: {
                fullNameEmpty: false,
                fullNameTooLong: false,
                companyTooLong: false,
                telephoneTooLong: false,
                passwordEmpty: false,
                passwordTooLong: false,
            },
            ready: false,
            sending: false,
            profileButtonValue: 'Update',
            passwordButtonValue: 'Change Password',
            error: null,
            errors: null,
            subStatus: false
        }
    },
    components: {
        Header,
        Sidebar
    },
    async created() {

        try {
            let response = await this.$http.get('/dashboard/settings');
            this.form.fullName = response.data.result.name;
            this.form.company = response.data.result.company;
            this.form.telephone = response.data.result.telephone;
        } catch(error) {
            try {
                await this.$store.dispatch('logout');
                this.$router.push({ name: 'Login'});
            } catch(error) {
                console.log(error);
            }
        }

        this.ready = true;

        this.$nextTick(() => {
            const togglePassword = document.querySelector('#toggleNewPassword');
            const password = document.querySelector('#password');
            togglePassword.addEventListener('click', function() {
                // toggle the type attribute
                let type = null;
                if (password.getAttribute('type') === 'password') {
                    type = 'text';
                    this.classList.remove('fa-eye-slash');
                    this.classList.add('fa-eye');
                } else {
                    type = 'password';
                    this.classList.remove('fa-eye');
                    this.classList.add('fa-eye-slash');
                }
                password.setAttribute('type', type);
            });
		});

        try {
            let response = await this.$http.get('/billing/subscription/status');

            if (response.data.result.expired == true && response.data.result.paid == false) {
                this.subStatus = false;
            } else {
                this.subStatus = true;
            }
        } catch(error) {
            this.error = error;
        }

    },
    computed: {
        hasSub() {
            return this.subStatus;
        },
    },
    methods: {
        async processProfile(evt) {

            evt.preventDefault();

            this.formErrors = this.validateProfile(this.form);

            // if formErrors is not empty
            if (Object.keys(this.formErrors).length) return;

            this.sending = true;
            this.profileButtonValue = "Updating...";

            try {
                await this.$http.post('/dashboard/settings',
                    {
                        fullName: this.form.fullName,
                        company: this.form.company,
                        telephone: this.form.telephone,
                    }
                );

                this.profileButtonValue = "Success!";

                setTimeout(() => {
                    this.profileButtonValue = 'Update';
                }, 5000);

            } catch(error) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                if (error.response.data.error) {
                    this.error = error.response.data.error;
                } else {
                    this.errors = error.response.data.errors;
                }
            } finally {
                this.sending = false;
            }
        },
        async processPassword(evt) {

            evt.preventDefault();

            this.formErrors = this.validatePassword(this.form);

            // if formErrors is not empty
            if (Object.keys(this.formErrors).length) return;

            this.sending = true;
            this.passwordButtonValue = "Updating...";

            try {
                await this.$http.post('/dashboard/password',
                    {
                        password: this.form.password
                    }
                );

                this.passwordButtonValue = "Success!";

                setTimeout(() => {
                    this.passwordButtonValue = 'Change Password';
                }, 5000);

            } catch(error) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                if (error.response.data.error) {
                    this.error = error.response.data.error;
                } else {
                    this.errors = error.response.data.errors;
                }
            } finally {
                this.sending = false;
            }
        },
        validateProfile(form) {

            // check the entire form in one go rather than updating formErrors one by one
            const errors = {};

            if (!form.fullName)
              errors.fullNameEmpty = true;

            if (form.fullName.length > 50)
              errors.fullNameTooLong = true;

            if (form.company.length > 50)
              errors.companyTooLong = true;

            if (form.telephone.length > 50)
              errors.telephoneTooLong = true;

            return errors;
        },
        validatePassword(form) {

            // check the entire form in one go rather than updating formErrors one by one
            const errors = {};

            if (!form.password)
              errors.passwordEmpty = true;

            if (form.password.length > 50)
              errors.passwordTooLong = true;

            return errors;
        }
    },
    watch: {
        'form.fullName'() {
            if (this.formErrors.fullNameEmpty || this.formErrors.fullNameTooLong) {
              this.formErrors.fullNameEmpty = false;
              this.formErrors.fullNameTooLong = false;
            }
        },
        'form.company'() {
            if (this.formErrors.companyTooLong)
              this.formErrors.companyTooLong = false;
        },
        'form.telephone'() {
            if (this.formErrors.telephoneTooLong)
              this.formErrors.telephoneTooLong = false;
        },
        'form.password'() {
            if (this.formErrors.passwordEmpty || this.formErrors.passwordTooLong) {
              this.formErrors.passwordEmpty = false;
              this.formErrors.passwordTooLong = false;
            }
        },
    },
    mounted() {

        $( document ).ready(function() {

          $('.simple-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.header-inner .right').toggleClass('open');
            $('body').toggleClass('overflow');
          });

          $('.dash-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.dash-nav').toggleClass('open');
            $('body').toggleClass('overflow');
            $('.dash-overflow').toggleClass('visible');
            $('.dash-reports').toggleClass('open');
          });

          $('.dash-overflow').on('click', function(){
            $('.dash-hamburger').removeClass('is-active');
            $('.dash-nav').removeClass('open');
            $('body').removeClass('overflow');
            $(this).removeClass('visible');
            $('.dash-reports').removeClass('open');
          });

          closeContentBlock();
          openLoginMenu();
          calcMainContentMargin();
          periodSelection();
          calculateContentMarginTop();
          addEventListenerForMenu();
        });

        function closeContentBlock() {
          const closeButtons = document.getElementsByClassName("close");

          for(let i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener("click", function (){
              closeButtons[i].parentElement.style.display = "none";
            });
          }
        }

        function openLoginMenu() {
          const accountMenu = document.getElementById("account-menu");
          const accountMenuPopup = document.getElementById("account-menu-popup");

          if (null !== accountMenu && null !== accountMenuPopup) {
            const toggleMenu = function() {
              accountMenuPopup.classList.toggle("hide-element");
            }

            accountMenu.addEventListener("click", function (e) {
              e.stopPropagation();
              toggleMenu();
            });

            document.addEventListener("click", function(e){
              const target = e.target;
              const itsAccountMenuPopup = target === accountMenuPopup || accountMenuPopup.contains(target);
              const itsAccountMenu = target === accountMenu;
              const menuIsActive = !accountMenuPopup.classList.contains("hide-element");

              if (!itsAccountMenuPopup && !itsAccountMenu && menuIsActive) {
                toggleMenu();
              }
            });
          }

        }

        function toggleDropdown() {
          $('.dropdown').on('click', function(){
            $('.dropdown-active + ul').slideToggle()
            let content = $('.dropdown-active + ul li.active a').text()
            $('.dropdown-active').html(content)
          })
        }
        toggleDropdown()

        function calcMainContentMargin() {
          const navigationBlock = document.getElementById("dash-navigation");
          const contentBlock = document.getElementsByClassName("dash-content");
          const footerBlock = document.getElementsByTagName("footer");

          if (
              null !== navigationBlock &&
              null !== contentBlock &&
              null !== footerBlock
          ) {
            const navigationBlockWidth = navigationBlock.offsetWidth;

            for(let i = 0; i < contentBlock.length; i++) {
              contentBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }

            for(let i = 0; i < footerBlock.length; i++) {
              footerBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }
          }

        }

        function periodSelection() {
          const allSelectionsMenu = document.getElementsByClassName("period-selection");

          for(let i = 0; i < allSelectionsMenu.length; i++) {

            const selectionsMenuLi =
                allSelectionsMenu[i].getElementsByTagName("ul")[0].getElementsByTagName("li");

            for(let i = 0; i < selectionsMenuLi.length; i++) {
              let anchor = selectionsMenuLi[i];
              anchor.onclick = function () {
                for (let i = 0; i < selectionsMenuLi.length; i++) {
                  selectionsMenuLi[i].classList.remove("active");
                }
                anchor.classList.add("active");
              }
            }

          }
        }

        function calculateContentMarginTop() {
          const headerHeight = $('header').height();
          $('.blog').css('margin-top', headerHeight + 'px');
          $('.dashboard').css('margin-top', headerHeight + 'px');
          $('.single').css('margin-top', headerHeight + 'px');
        }
        window.onresize = calculateContentMarginTop;

        function addEventListenerForMenu() {
          $('.main-pages-right').children('nav').children('ul').children('li').each(function () {
            $(this).on('click', function () {
              if ($('#homepage_hamburger_menu').css('display') === 'block') {
                $('.simple-hamburger').toggleClass('is-active');
                $('.header-inner .right').toggleClass('open');
                $('body').toggleClass('overflow');
              }
            });
          });
        }

    }
}
</script>
