<template>
  <div>
      <Header />
      <div class="dash-overflow"></div>
      <div class="dashboard dashboard-keywords">
        <Sidebar :reports="false" :addWeb="false" :websitesButton="false" />
        <div class="dash-content">
          <div class="steps-block">
            <div class="step current-step">
              <div class="circle-block">
                <div class="circle">1</div>
              </div>
              <div class="step-ttl">Add a website</div>
            </div>
            <div class="line"></div>
            <div class="step">
              <div class="circle-block">
                <div class="circle">2</div>
              </div>
              <div class="step-ttl">Insert code</div>
            </div>
          </div>
          <div class="dash-inner-content">
            <h1>Welcome.</h1>
            <p>Please add a website to get started.</p>
            <form @submit="submitForm">
              <div class="form-group" style="margin-top: 24px;">
                <label class="raz input-field-with-hint">
                  <input v-model="website" type="text" maxlength="50" placeholder=" " />
                  <span>Domain name</span>
                  <p class="error" v-if="formErrors.websiteEmpty">Domain name is required.</p>
                  <p class="error" v-if="formErrors.websiteTooLong">Domain name be shorter than 50 characters.</p>
                  <p class="error" v-if="formErrors.websiteInvalid">Domain name must be in the form "website.com".</p>
                  <p class="error" v-if="error">{{ error }}</p>
                </label>
                <div class="hint-construction">
                  <img src="/img/dashboard/question-icon.svg" class="question-icon" alt="">
                  <div class="info-popup">
                    Domain of the website.<br>
                    For example, website.com
                  </div>
                </div>
              </div>
              <div class="form-group">
                <input type="submit" class="next-step" value="Next">
              </div>
            </form>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Header from '@/components/dashboard/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';
import $ from 'jquery';

export default {
    name: 'AddFirstWebsite',
    data() {
        return {
            website: null,
            error: null,
            formErrors: {
                websiteEmpty: false,
                websiteTooLong: false,
                websiteInvalid: false
            },
        }
    },
    components: {
            Header,
            Sidebar,
    },
    methods: {

        async submitForm(evt) {

            evt.preventDefault();

            this.clearErrors();

            this.formErrors = this.validateForm(this.website);

            // if formErrors is not empty
            if (Object.keys(this.formErrors).length) return;

            // everything ok
            return this.addWebsite(this.website);
        },
        clearErrors() {

            this.error = null;
            this.formErrors.websiteEmpty = false;
            this.formErrors.websiteTooLong = false;
            this.formErrors.websiteInvalid = false;

        },
        validateForm(website) {

            // check the entire form in one go rather than updating formErrors one by one
            const errors = {};

            if (!website)
              errors.websiteEmpty = true;

            if (website && website.length > 50)
              errors.websiteTooLong = true;

            if (website && !(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](\.[a-zA-Z]{2,})+$/.test(website)))
              errors.websiteInvalid = true;

            if (website && website.substring(0,4) == 'www.')
              errors.websiteInvalid = true;

            return errors;
        },
        async addWebsite(evt) {

            this.website = evt;

            try {
                await this.$http.post('dashboard/website/create', {
                    website: this.website
                });

                return this.$router.push({
                    name: 'AddCode',
                    params: { website: this.website }
                });

            } catch(error) {
                this.error = error.response.data.error;
            }

        }
    },
    watch: {
        'form.website'() {
            if (this.formErrors.websiteEmpty || this.formErrors.websiteTooLong || this.formErrors.websiteInvalid) {
              this.formErrors.websiteEmpty = false;
              this.formErrors.websiteTooLong = false;
              this.formErrors.websiteInvalid = false;
            }
        },
    },
    mounted() {
        $( document ).ready(function() {

          $('.simple-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.header-inner .right').toggleClass('open');
            $('body').toggleClass('overflow');
          });

          $('.dash-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.dash-nav').toggleClass('open');
            $('body').toggleClass('overflow');
            $('.dash-overflow').toggleClass('visible');
          });

          $('.dash-overflow').on('click', function(){
            $('.dash-hamburger').removeClass('is-active');
            $('.dash-nav').removeClass('open');
            $('body').removeClass('overflow');
            $(this).removeClass('visible');
          });

          closeContentBlock();
          openLoginMenu();
          calcMainContentMargin();
          periodSelection();
          calculateContentMarginTop();
          addEventListenerForMenu();
        });

        function closeContentBlock() {
          const closeButtons = document.getElementsByClassName("close");

          for(let i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener("click", function (){
              closeButtons[i].parentElement.style.display = "none";
            });
          }
        }

        function openLoginMenu() {
          const accountMenu = document.getElementById("account-menu");
          const accountMenuPopup = document.getElementById("account-menu-popup");

          if (null !== accountMenu && null !== accountMenuPopup) {
            const toggleMenu = function() {
              accountMenuPopup.classList.toggle("hide-element");
            }

            accountMenu.addEventListener("click", function (e) {
              e.stopPropagation();
              toggleMenu();
            });

            document.addEventListener("click", function(e){
              const target = e.target;
              const itsAccountMenuPopup = target === accountMenuPopup || accountMenuPopup.contains(target);
              const itsAccountMenu = target === accountMenu;
              const menuIsActive = !accountMenuPopup.classList.contains("hide-element");

              if (!itsAccountMenuPopup && !itsAccountMenu && menuIsActive) {
                toggleMenu();
              }
            });
          }

        }

        function toggleDropdown() {
          $('.dropdown').on('click', function(){
            $('.dropdown-active + ul').slideToggle()
            let content = $('.dropdown-active + ul li.active a').text()
            console.log(content)
            $('.dropdown-active').html(content)
          })
        }
        toggleDropdown()

        function calcMainContentMargin() {
          const navigationBlock = document.getElementById("dash-navigation");
          const contentBlock = document.getElementsByClassName("dash-content");
          const footerBlock = document.getElementsByTagName("footer");

          if (
              null !== navigationBlock &&
              null !== contentBlock &&
              null !== footerBlock
          ) {
            const navigationBlockWidth = navigationBlock.offsetWidth;

            for(let i = 0; i < contentBlock.length; i++) {
              contentBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }

            for(let i = 0; i < footerBlock.length; i++) {
              footerBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }
          }

        }

        function periodSelection() {
          const allSelectionsMenu = document.getElementsByClassName("period-selection");

          for(let i = 0; i < allSelectionsMenu.length; i++) {

            const selectionsMenuLi =
                allSelectionsMenu[i].getElementsByTagName("ul")[0].getElementsByTagName("li");

            for(let i = 0; i < selectionsMenuLi.length; i++) {
              let anchor = selectionsMenuLi[i];
              anchor.onclick = function () {
                for (let i = 0; i < selectionsMenuLi.length; i++) {
                  selectionsMenuLi[i].classList.remove("active");
                }
                anchor.classList.add("active");
              }
            }

          }
        }

        function calculateContentMarginTop() {
          const headerHeight = $('header').height();
          $('.blog').css('margin-top', headerHeight + 'px');
          $('.dashboard').css('margin-top', headerHeight + 'px');
          $('.single').css('margin-top', headerHeight + 'px');
        }
        window.onresize = calculateContentMarginTop;

        function addEventListenerForMenu() {
          $('.main-pages-right').children('nav').children('ul').children('li').each(function () {
            $(this).on('click', function () {
              if ($('#homepage_hamburger_menu').css('display') === 'block') {
                $('.simple-hamburger').toggleClass('is-active');
                $('.header-inner .right').toggleClass('open');
                $('body').toggleClass('overflow');
              }
            });
          });
        }
    }
}
</script>

<style>
    .error, .error-large {
        margin: 2px 0 0 0 !important;
        color: #FF7F7F !important;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        padding: 2px 6px;
    }
    .error-large {
        font-size: large;
        text-align: center;
    }
</style>
