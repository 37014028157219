<template>
  <div>
      <Header />
      <div class="dash-overflow"></div>
      <div class="dashboard dashboard-keywords">
        <Sidebar :reports="false" :addWeb="hasSub" :websitesButton="false" />
        <div class="dash-content">
            <h1 class="ttl">Settings</h1>

            <div class="navigation-block">
              <div>
                <router-link :to="{ name: 'Settings' }">Account</router-link>
              </div>
              <div class="active">
                <router-link :to="{ name: 'Subscription' }">Subscription</router-link>
              </div>
            </div>

            <div>
              <ul class="breadcrumb">
                <li><router-link :to="{ name: 'Subscription' }">Subscription</router-link></li>
                <li><router-link :to="{ name: 'Upgrade' }">Upgrade</router-link></li>
                <li>Confirm</li>
              </ul>
            </div>

            <div v-if="ready" class="dash-inner-content">

              <h1 v-if="parseInt(plan.clicks.replace(/,/g, '')) > parseInt(clicks.replace(/,/g, ''))" class="ttl">Upgrade Confirmation</h1>
              <h1 v-if="parseInt(plan.clicks.replace(/,/g, '')) < parseInt(clicks.replace(/,/g, ''))" class="ttl">Downgrade Confirmation</h1>

              <div class="settings-block mb-16 active-block">
                <div class="settings-block-inner">
                    <div class="settings-form-group padding-top-bottom-8">
                      <div class="settings-form-column">
                        <div class="settings-block-small-content blue-text">
                          Selected
                        </div>
                        <div class="settings-block-large-content">
                          {{ plan.title }}
                        </div>
                        <div class="settings-block-middle-content gray-text">
                          {{ plan.sub }}<br>{{ plan.sub2 }}
                        </div>
                      </div>

                    </div>

                    <div class="settings-form-group border-top-gray padding-top-bottom-8">
                      <div class="settings-form-column">
                        <div v-if="parseInt(plan.clicks.replace(/,/g, '')) > parseInt(clicks.replace(/,/g, ''))" class="hint">
                          New subscription will begin immediately and the first month's payment may be prorated.
                        </div>
                        <div v-if="parseInt(plan.clicks.replace(/,/g, '')) < parseInt(clicks.replace(/,/g, ''))" class="hint">
                          New subscription will begin immediately and may include in a prorated refund.
                        </div>
                      </div>

                    </div>

                </div>
              </div>

              <div class="add-payment mb-16">
                <a @click="upgrade" href="#" class="add-new approve">
                  <div class="text-field">
                    {{ buttonText }}
                  </div>
               </a>
              </div>

          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Header from '@/components/dashboard/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';
import $ from 'jquery';

export default {
    name: 'UpgradeConfirm',
    data() {
        return {
            buttonText: "Confirm",
            plan: null,
            clicks: null,
            selected: null,
            error: null,
            ready: false,
            subStatus: false,
            sending: false
        }
    },
    components: {
        Header,
        Sidebar
    },
    async created() {

        // check if he has a sub
        try {
            let response = await this.$http.get('/billing/subscription/status');

            this.$store.dispatch('setPaid', response.data.result.paid == true);

            if (response.data.result.expired && this.clicks) {
                // no active subscription
                this.subStatus = false;

            } else if (response.data.result.expired) {

                this.subStatus = false;

            } else {
                this.subStatus = true;
            }
        } catch(error) {
            this.error = error;
        }

        if (this.$route.params.plan && this.$route.params.existing) {
            this.plan = this.$route.params.plan;
            this.clicks = this.$route.params.existing;
        } else {
            return this.$router.push({
                name: 'Upgrade'
            });
        }

        this.ready = true;
    },
    methods: {
        async upgrade(evt) {

            evt.preventDefault();

            if (this.sending == true) return;

            this.sending = true;

            this.buttonText = "Please wait...";

            try {
                const response = await this.$http.post('/billing/subscription/update',
                    {
                        price_id: this.plan.price_id,
                        product_id: this.plan.product_id,
                    }
                );

                if (typeof response.data.result === 'object'
                        && 'email' in response.data.result
                        && 'key' in response.data.result
                        && 'secret' in response.data.result)  {

                    return this.$router.push({
                        name: 'Payment',
                        params: response.data.result
                    });

                } else {
                    return this.$router.push({
                        name: 'Subscription',
                        params: { 'reload': true }
                    });
                }

            } catch(error) {
                this.error = error.response.data.error;
            }

        }
    },
    computed: {
        hasSub() {
            return this.subStatus;
        },
    },
    mounted() {
        $( document ).ready(function() {

          $('.simple-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.header-inner .right').toggleClass('open');
            $('body').toggleClass('overflow');
          });

          $('.dash-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.dash-nav').toggleClass('open');
            $('body').toggleClass('overflow');
            $('.dash-overflow').toggleClass('visible');
          });

          $('.dash-overflow').on('click', function(){
            $('.dash-hamburger').removeClass('is-active');
            $('.dash-nav').removeClass('open');
            $('body').removeClass('overflow');
            $(this).removeClass('visible');
          });

          closeContentBlock();
          openLoginMenu();
          calcMainContentMargin();
          periodSelection();
          calculateContentMarginTop();
          addEventListenerForMenu();
        });

        function closeContentBlock() {
          const closeButtons = document.getElementsByClassName("close");

          for(let i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener("click", function (){
              closeButtons[i].parentElement.style.display = "none";
            });
          }
        }

        function openLoginMenu() {
          const accountMenu = document.getElementById("account-menu");
          const accountMenuPopup = document.getElementById("account-menu-popup");

          if (null !== accountMenu && null !== accountMenuPopup) {
            const toggleMenu = function() {
              accountMenuPopup.classList.toggle("hide-element");
            }

            accountMenu.addEventListener("click", function (e) {
              e.stopPropagation();
              toggleMenu();
            });

            document.addEventListener("click", function(e){
              const target = e.target;
              const itsAccountMenuPopup = target === accountMenuPopup || accountMenuPopup.contains(target);
              const itsAccountMenu = target === accountMenu;
              const menuIsActive = !accountMenuPopup.classList.contains("hide-element");

              if (!itsAccountMenuPopup && !itsAccountMenu && menuIsActive) {
                toggleMenu();
              }
            });
          }

        }

        function toggleDropdown() {
          $('.dropdown').on('click', function(){
            $('.dropdown-active + ul').slideToggle()
            let content = $('.dropdown-active + ul li.active a').text()
            $('.dropdown-active').html(content)
          })
        }
        toggleDropdown()

        function calcMainContentMargin() {
          const navigationBlock = document.getElementById("dash-navigation");
          const contentBlock = document.getElementsByClassName("dash-content");
          const footerBlock = document.getElementsByTagName("footer");

          if (
              null !== navigationBlock &&
              null !== contentBlock &&
              null !== footerBlock
          ) {
            const navigationBlockWidth = navigationBlock.offsetWidth;

            for(let i = 0; i < contentBlock.length; i++) {
              contentBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }

            for(let i = 0; i < footerBlock.length; i++) {
              footerBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }
          }

        }

        function periodSelection() {
          const allSelectionsMenu = document.getElementsByClassName("period-selection");

          for(let i = 0; i < allSelectionsMenu.length; i++) {

            const selectionsMenuLi =
                allSelectionsMenu[i].getElementsByTagName("ul")[0].getElementsByTagName("li");

            for(let i = 0; i < selectionsMenuLi.length; i++) {
              let anchor = selectionsMenuLi[i];
              anchor.onclick = function () {
                for (let i = 0; i < selectionsMenuLi.length; i++) {
                  selectionsMenuLi[i].classList.remove("active");
                }
                anchor.classList.add("active");
              }
            }

          }
        }

        function calculateContentMarginTop() {
          const headerHeight = $('header').height();
          $('.blog').css('margin-top', headerHeight + 'px');
          $('.dashboard').css('margin-top', headerHeight + 'px');
          $('.single').css('margin-top', headerHeight + 'px');
        }
        window.onresize = calculateContentMarginTop;

        function addEventListenerForMenu() {
          $('.main-pages-right').children('nav').children('ul').children('li').each(function () {
            $(this).on('click', function () {
              if ($('#homepage_hamburger_menu').css('display') === 'block') {
                $('.simple-hamburger').toggleClass('is-active');
                $('.header-inner .right').toggleClass('open');
                $('body').toggleClass('overflow');
              }
            });
          });
        }
    }
}
</script>

<style>
    .error, .error-large {
        margin: 2px 0 0 0 !important;
        color: #FF7F7F !important;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        padding: 2px 6px;
    }
    .error-large {
        font-size: large;
        text-align: center;
    }
</style>
