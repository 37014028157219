<template>
  <div>
      <Header />
      <div class="dash-overflow"></div>
      <div class="dashboard">
        <Sidebar :reports="true" :addWeb="true" :webButtonActive="false" :googleButtonActive="false" :website="website" />
        <div class="dash-content">

          <h1 class="ttl">Sources</h1>
          <div class="desc">The charts below show the amount of click fraud {{ website }} has received from each ad network.
          </div>

          <div class="dash-inner-content">

              <div class="p-block">
                <p>Polygraph uses <strong>utm_source</strong> to access your source data.</p>
              </div>

              <div class="dash-inner-content">
                <div class="mob flex-center white-text mobile-filter">
                  <div class="period-selection dropdown">
                    <span class="dropdown-active">This month</span>
                    <ul style="display: none;">
                      <li @click="updateCharts(0)" class="active"><span>This month</span></li>
                      <li @click="updateCharts(1)"><span>{{ getMonth(1) }}</span></li>
                      <li @click="updateCharts(2)"><span>{{ getMonth(2) }}</span></li>
                      <li @click="updateCharts(3)"><span>{{ getMonth(3) }}</span></li>
                      <li @click="updateCharts(4)"><span>{{ getMonth(4) }}</span></li>
                      <li @click="updateCharts(5)"><span>{{ getMonth(5) }}</span></li>
                      <li @click="updateCharts(6)"><span>Past 6 months</span></li>
                    </ul>
                  </div>
                </div>

                <div class="overflow-x-auto desktop-filter" style="margin-top: 24px;">

                  <div class="period-selection m-width-650 ">
                    <ul>
                      <li @click="updateCharts(0)" class="active">This month</li>
                      <li @click="updateCharts(1)"><span>{{ getMonth(1) }}</span></li>
                      <li @click="updateCharts(2)"><span>{{ getMonth(2) }}</span></li>
                      <li @click="updateCharts(3)"><span>{{ getMonth(3) }}</span></li>
                      <li @click="updateCharts(4)"><span>{{ getMonth(4) }}</span></li>
                      <li @click="updateCharts(5)"><span>{{ getMonth(5) }}</span></li>
                      <li @click="updateCharts(6)"><span>Past 6 months</span></li>
                    </ul>
                  </div>

                </div>

                <div class="chart-list" style="margin-top: 16px;">

                  <div v-if="chart1Ready" class="chart">
                      <div class="chart-title">
                        Click fraud by ad network
                      </div>
                      <chart :data="barChart1Data" :options="barChart1Options" :height="100" />
                  </div>

                  <div v-if="chart2Ready" class="chart">
                    <div class="chart-title">
                      Click fraud by ad network (% of total clicks)
                    </div>
                    <chart :data="barChart2Data" :options="barChart2Options" :height="100" />
                  </div>

                  <div v-if="!chart1Ready" class="chart">
                      <div class="chart-title">
                          Click fraud by ad network
                      </div>
                      No data.
                  </div>

                  <div v-if="!chart2Ready" class="chart">
                    <div class="chart-title">
                      Click fraud by ad network (as a percentage of total clicks)
                    </div>
                    No data.
                  </div>

                </div>

              </div>

          </div>

          <div class="sub padded">Next steps</div>
          <div class="desc">Reduce your ad spend at the poor performing ad networks.</div>

      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/dashboard/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';
import $ from 'jquery';

export default {
    name: 'Sources',
    data() {
        return {
            ready: false,
            error: null,
            newAccount: false,
            utmWarning: false,
            sources: null,
            chart1Ready: false,
            chart2Ready: false,
            barChart1Data: {},
            barChart2Data: {},
            barChart1Options: {
              type: 'horizontalBar',
              layout: {
                  padding: 0
              },
              responsive: true,
              legend: {
                display: false,
              },
              tooltips: {
                backgroundColor: "#17BF62",
              },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      fontSize: 14,
                      fontFamily: "'Inter', sans-serif",
                      fontColor: '#fff',
                      stepSize: 500,
                    },
                    gridLines: {
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      fontSize: 14,
                      fontFamily: "'Inter', sans-serif",
                      fontColor: '#fff',
                      padding: 10
                    },
                    gridLines: {
                      display: false,
                    },
                  },
                ],
              },
            },
            barChart2Options: {
              type: 'horizontalBar',
              layout: {
                  padding: 0
              },
              responsive: true,
              legend: {
                display: false,
              },
              tooltips: {
                backgroundColor: "#17BF62",
                callbacks: {
                  label: function(tooltipItem, data) {
                    return data['labels'][tooltipItem['index']] + ': ' + data['datasets'][0]['data'][tooltipItem['index']] + '%';
                  }
                }
              },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      fontSize: 14,
                      fontFamily: "'Inter', sans-serif",
                      fontColor: '#fff',
                      min: 0,
                      stepSize: 20,
                      max: 0 + 20 * 5,
                      callback: function(value) {
                         return ((value / 100) * 100) + '%';
                      }
                    },
                    gridLines: {
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      fontSize: 14,
                      fontFamily: "'Inter', sans-serif",
                      fontColor: '#fff',
                      padding: 10
                    },
                    gridLines: {
                      display: false,
                    },
                  },
                ],
              },
            },
        }
    },
    props: ['website'],
    components: {
        Header,
        Sidebar
    },
    async created() {
        // make sure he is authorised to view this website
        try {
            let response = await this.$http.get('/dashboard/website/' + this.website + '/verify');
            this.active = response.data.result.active;
        } catch(error) {
            try {
                await this.$store.dispatch('logout');
                this.$router.push({ name: 'Login'});
            } catch(error) {
                // do nothing
            }
        }

        // get sources
        this.getSources(0);
    },
    methods: {
        getMonth(val) {
            const current = new Date();
            current.setMonth(current.getMonth()-val);

            // deal with leap year
            if (current.toLocaleString('default', { month: 'long' }) == new Date().toLocaleString("default", { month: "long" })) {
                // leap year
                current.setMonth(current.getMonth()-1);
                return current.toLocaleString('default', { month: 'long' });
            } else {
                return current.toLocaleString('default', { month: 'long' });
            }
        },
        updateCharts(val) {
            this.chart1Ready = false;
            this.chart2Ready = false;
            this.sources = null;
            this.getSources(val);
        },
        async getSources(month) {
            try {
                let response = await this.$http.get('/dashboard/website/' + this.website + '/sources?month=' + month);

                if (!Object.keys(response.data.sources).length) {
                    // no sources
                    // check if new account
                    let response = await this.$http.get('/dashboard/account');
                    if (response.data.result == true) {
                        this.newAccount = true;
                    } else {
                        this.utmWarning = true;
                    }

                } else {
                    this.sources = response.data;
                    // create charts
                    this.parseData();
                }

                // JUST FOR TESTING, REMOVE AFTER NULL
                //this.parseData();

            } catch(error) {
                console.log(error);
            }

            this.ready = true;
        },

      parseData() {

        let barChart1Data = {
          labels: [],
          datasets: [
            {
                data: [],
                backgroundColor: [],
                categoryPercentage: 1,
                barPercentage: 1,
                barThickness: 15,
            },
          ],
        };

        let barChart2Data = {
          labels: [],
          datasets: [
            {
                data: [],
                backgroundColor: [],
                categoryPercentage: 1,
                barPercentage: 1,
                barThickness: 15,
            },
          ],
        };

        let colors = [
            "rgb(147, 104, 255)",
            "rgb(24, 119, 242)",
        ];

/*
        this.sources = null;
        this.sources = {};
        this.sources.percent = [
            {
                percentage: 7,
                utm_source: "Google Display"
            },
            {
                percentage: 16,
                utm_source: "Microsoft Ads"
            },
            {
                percentage: 11,
                utm_source: "PopAds"
            }
        ];
        this.sources.sources = [
            {
                fs_hits_total: 2200,
                utm_source: "Google Display"
            },
            {
                fs_hits_total: 3750,
                utm_source: "Microsoft Ads"
            },
            {
                fs_hits_total: 1117,
                utm_source: "PopAds"
            }
        ];
*/

        if (this.sources) {
            // chart 1
            this.sources.sources.forEach((element) => {
              barChart1Data.labels.push(element.utm_source)
              barChart1Data.datasets[0].data.push(element.fs_hits_total)
            });

            for (let i = 0; i < barChart1Data.datasets[0].data.length; i++) {
                barChart1Data.datasets[0].backgroundColor.push(colors[i % colors.length]);
            }

            this.barChart1Data = barChart1Data;

            // chart 2
            this.sources.percent.forEach((element) => {
              barChart2Data.labels.push(element.utm_source)
              barChart2Data.datasets[0].data.push(element.percentage);
            });

            for (let i = 0; i < barChart2Data.datasets[0].data.length; i++) {
                barChart2Data.datasets[0].backgroundColor.push(colors[i % colors.length]);
            }

            this.barChart2Data = barChart2Data;
        }

        this.chart1Ready = true;
        this.chart2Ready = true;

      },

    },
    mounted() {

        $( document ).ready(function() {

          $('.simple-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.header-inner .right').toggleClass('open');
            $('body').toggleClass('overflow');
          });

          $('.dash-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.dash-nav').toggleClass('open');
            $('body').toggleClass('overflow');
            $('.dash-overflow').toggleClass('visible');
            $('.dash-reports').toggleClass('open');
          });

          $('.dash-overflow').on('click', function(){
            $('.dash-hamburger').removeClass('is-active');
            $('.dash-nav').removeClass('open');
            $('body').removeClass('overflow');
            $(this).removeClass('visible');
            $('.dash-reports').removeClass('open');
          });

          closeContentBlock();
          openLoginMenu();
          calcMainContentMargin();
          periodSelection();
          calculateContentMarginTop();
          addEventListenerForMenu();
        });

        function closeContentBlock() {
          const closeButtons = document.getElementsByClassName("close");

          for(let i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener("click", function (){
              closeButtons[i].parentElement.style.display = "none";
            });
          }
        }

        function openLoginMenu() {
          const accountMenu = document.getElementById("account-menu");
          const accountMenuPopup = document.getElementById("account-menu-popup");

          if (null !== accountMenu && null !== accountMenuPopup) {
            const toggleMenu = function() {
              accountMenuPopup.classList.toggle("hide-element");
            }

            accountMenu.addEventListener("click", function (e) {
              e.stopPropagation();
              toggleMenu();
            });

            document.addEventListener("click", function(e){
              const target = e.target;
              const itsAccountMenuPopup = target === accountMenuPopup || accountMenuPopup.contains(target);
              const itsAccountMenu = target === accountMenu;
              const menuIsActive = !accountMenuPopup.classList.contains("hide-element");

              if (!itsAccountMenuPopup && !itsAccountMenu && menuIsActive) {
                toggleMenu();
              }
            });
          }

        }

        function toggleDropdown() {
            $('.dropdown').on('click', function(){
                let content = $('.dropdown-active + ul li.active span').text()
                $('.dropdown-active + ul').slideToggle()
                if (content) {
                    $('.dropdown-active').html(content)
                }
            })
        }
        toggleDropdown()

        function calcMainContentMargin() {
          const navigationBlock = document.getElementById("dash-navigation");
          const contentBlock = document.getElementsByClassName("dash-content");
          const footerBlock = document.getElementsByTagName("footer");

          if (
              null !== navigationBlock &&
              null !== contentBlock &&
              null !== footerBlock
          ) {
            const navigationBlockWidth = navigationBlock.offsetWidth;

            for(let i = 0; i < contentBlock.length; i++) {
              contentBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }

            for(let i = 0; i < footerBlock.length; i++) {
              footerBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }
          }

        }

        function periodSelection() {
          const allSelectionsMenu = document.getElementsByClassName("period-selection");

          for(let i = 0; i < allSelectionsMenu.length; i++) {

            const selectionsMenuLi =
                allSelectionsMenu[i].getElementsByTagName("ul")[0].getElementsByTagName("li");

            for(let i = 0; i < selectionsMenuLi.length; i++) {
              let anchor = selectionsMenuLi[i];
              anchor.onclick = function () {
                for (let i = 0; i < selectionsMenuLi.length; i++) {
                  selectionsMenuLi[i].classList.remove("active");
                }
                anchor.classList.add("active");
              }
            }

          }
        }

        function calculateContentMarginTop() {
          const headerHeight = $('header').height();
          $('.blog').css('margin-top', headerHeight + 'px');
          $('.dashboard').css('margin-top', headerHeight + 'px');
          $('.single').css('margin-top', headerHeight + 'px');
        }
        window.onresize = calculateContentMarginTop;

        function addEventListenerForMenu() {
          $('.main-pages-right').children('nav').children('ul').children('li').each(function () {
            $(this).on('click', function () {
              if ($('#homepage_hamburger_menu').css('display') === 'block') {
                $('.simple-hamburger').toggleClass('is-active');
                $('.header-inner .right').toggleClass('open');
                $('body').toggleClass('overflow');
              }
            });
          });
        }

    }
}
</script>
