<template>
  <div>
      <Header />
      <div class="dash-overflow"></div>
      <div class="dashboard dashboard-keywords">
        <Sidebar />
        <div v-show="ready" class="dash-content">
          <div class="dash-inner-content">
            <div class="web-list-block">
              <h1>Dashboard</h1>

              <h3>Recent Customers</h3>
              <div class="overflow-x-auto">
                <div v-if="customers && customers.length" class="web-table">
                  <div v-for="customer in customers" :key="customer.id" class="table-body">
                    <div style="width: 20%">{{ expiry(customer.created_at) }}</div>
                    <div style="width: 15%">{{ customer.name }}</div>
                    <div style="width: 20%">{{ customer.company }}</div>
                    <div style="width: 15%">{{ customer.email }}</div>
                    <div style="width: 10%">{{ active(customer.active) }}</div>
                  </div>
                </div>
              </div>

              <br />
              <h3>Recent Subscriptions</h3>
              <div class="overflow-x-auto">
                <div v-if="subscriptions && subscriptions.length" class="web-table">
                  <div v-for="subscription in subscriptions" :key="subscription.id" class="table-body">
                    <div style="width: 20%">{{ expiry(subscription.created_at) }}</div>
                    <div style="width: 15%">{{ subscription.name }}</div>
                    <div style="width: 15%">{{ subscription.allowance }}</div>
                    <div style="width: 15%">{{ subscription.status.charAt(0).toUpperCase() + subscription.status.substring(1) }}</div>
                  </div>
                </div>
              </div>

              <br />
              <h3>Recent Traffic</h3>
              <div class="overflow-x-auto">
                <div v-if="traffic && traffic.length" class="web-table">
                  <div v-for="t in traffic" :key="t.id" class="table-body">
                    <div style="width: 20%">{{ expiry(t.created_at) }}</div>
                    <div style="width: 15%">{{ t.name }}</div>
                    <div style="width: 15%">{{ t.domain }}</div>
                    <div style="width: 15%">{{ t.utm_source }}</div>
                  </div>
                </div>
              </div>

              <br />
              <h3>Recent Fraud</h3>
              <div class="overflow-x-auto">
                <div v-if="fraud && fraud.length" class="web-table">
                  <div v-for="f in fraud" :key="f.id" class="table-body">
                      <div style="width: 20%">{{ expiry(f.created_at) }}</div>
                      <div style="width: 15%">{{ f.name }}</div>
                      <div style="width: 15%">{{ f.domain }}</div>
                      <div style="width: 15%">{{ f.utm_source }}</div>
                      <div style="width: 35%">{{ f.reason }}</div>
                  </div>
                </div>
              </div>

              <br />
              <h3>Recent Errors</h3>
              <div class="overflow-x-auto">
                <div v-if="errors && errors.length" class="web-table">
                  <div v-for="error in errors" :key="error.id" class="table-body">
                      <div style="width: 20%">{{ expiry(error.created_at) }}</div>
                      <div style="width: 15%">{{ error.func }}</div>
                      <div style="width: 45%">{{ error.error }}</div>
                  </div>
                </div>
              </div>

              <br />
              <h3>Recent Lost</h3>
              <div class="overflow-x-auto">
                <div v-if="lost && lost.length" class="web-table">
                  <div v-for="l in lost" :key="l.id" class="table-body">
                      <div style="width: 20%">{{ expiry(l.created_at) }}</div>
                      <div style="width: 15%">{{ l.domain }}</div>
                      <div style="width: 45%">{{ l.reason }}</div>
                  </div>
                </div>
              </div>

              <br />
              <h3>Recent Headless Data Centers</h3>
              <div class="overflow-x-auto">
                <div v-if="headless && headless.length" class="web-table">
                  <div v-for="h in headless" :key="h.id" class="table-body">
                    <div style="width: 45%">{{ h.name }}</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Header from '@/components/dashboard/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';
import $ from 'jquery';

export default {
    name: 'Dashboard',
    data() {
        return {
            ready: false,
            error: '',
            customers: [],
            subscriptions: [],
            traffic: [],
            fraud: [],
            errors: [],
            lost: [],
            headless: []
        }
    },
    components: {
        Header,
        Sidebar
    },
    methods: {
        expiry(sub) {

            let ts = new Date(sub);
            ts = ts.toISOString();
            ts = ts.replace("T", " ");
            ts = ts.replace(/\..*/, "");
            ts = ts.toString();
            const t = ts.split(/[- :]/);
            ts = new Date(Date.UTC(ts[0], ts[1]-1, ts[2], ts[3], ts[4], ts[5]));

            const d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
            const options = {
                year:
                'numeric',
                month: 'long',
                day: 'numeric',
                hourCycle: 'h23',
                hour: '2-digit',
                minute:'2-digit'
            };

            return(d.toLocaleDateString('en-US', options));

        },
        active(val) {
            return val ? 'Active' : 'Inactive';
        }

    },
    async created() {

        // get the customers
        try {
            let response = await this.$http.get('/dashboard/customers');

            console.log(response);

            this.customers = response.data.customers;

        } catch(error) {
            this.error = error;
        }

        // get the subscriptions
        try {
            let response = await this.$http.get('/dashboard/subscriptions');

            this.subscriptions = response.data.subscriptions;

        } catch(error) {
            this.error = error;
        }

        // get the traffic
        try {
            let response = await this.$http.get('/dashboard/traffic');

            this.traffic = response.data.traffic;

        } catch(error) {
            this.error = error;
        }

        // get the fraud
        try {
            let response = await this.$http.get('/dashboard/fraud');

            this.fraud = response.data.fraud;

        } catch(error) {
            this.error = error;
        }

        // get the errors
        try {
            let response = await this.$http.get('/dashboard/errors');

            this.errors = response.data.errors;

        } catch(error) {
            this.error = error;
        }

        // get the lost
        try {
            let response = await this.$http.get('/dashboard/lost');

            this.lost = response.data.lost;

        } catch(error) {
            this.error = error;
        }

        // get the headless data centers
        try {
            let response = await this.$http.get('/dashboard/headless');

            this.headless = response.data.headless;

        } catch(error) {
            this.error = error;
        }

        this.ready = true;

    },
    mounted() {

        $( document ).ready(function() {

          $('.simple-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.header-inner .right').toggleClass('open');
            $('body').toggleClass('overflow');
          });

          $('.dash-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.dash-nav').toggleClass('open');
            $('body').toggleClass('overflow');
            $('.dash-overflow').toggleClass('visible');
          });

          $('.dash-overflow').on('click', function(){
            $('.dash-hamburger').removeClass('is-active');
            $('.dash-nav').removeClass('open');
            $('body').removeClass('overflow');
            $(this).removeClass('visible');
          });

          closeContentBlock();
          openLoginMenu();
          calcMainContentMargin();
          periodSelection();
          calculateContentMarginTop();
          addEventListenerForMenu();
        });

        function closeContentBlock() {
          const closeButtons = document.getElementsByClassName("close");

          for(let i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener("click", function (){
              closeButtons[i].parentElement.style.display = "none";
            });
          }
        }

        function openLoginMenu() {
          const accountMenu = document.getElementById("account-menu");
          const accountMenuPopup = document.getElementById("account-menu-popup");

          if (null !== accountMenu && null !== accountMenuPopup) {
            const toggleMenu = function() {
              accountMenuPopup.classList.toggle("hide-element");
            }

            accountMenu.addEventListener("click", function (e) {
              e.stopPropagation();
              toggleMenu();
            });

            document.addEventListener("click", function(e){
              const target = e.target;
              const itsAccountMenuPopup = target === accountMenuPopup || accountMenuPopup.contains(target);
              const itsAccountMenu = target === accountMenu;
              const menuIsActive = !accountMenuPopup.classList.contains("hide-element");

              if (!itsAccountMenuPopup && !itsAccountMenu && menuIsActive) {
                toggleMenu();
              }
            });
          }

        }

        function toggleDropdown() {
          $('.dropdown').on('click', function(){
            $('.dropdown-active + ul').slideToggle()
            let content = $('.dropdown-active + ul li.active a').text()
            console.log(content)
            $('.dropdown-active').html(content)
          })
        }
        toggleDropdown()

        function calcMainContentMargin() {
          const navigationBlock = document.getElementById("dash-navigation");
          const contentBlock = document.getElementsByClassName("dash-content");
          const footerBlock = document.getElementsByTagName("footer");

          if (
              null !== navigationBlock &&
              null !== contentBlock &&
              null !== footerBlock
          ) {
            const navigationBlockWidth = navigationBlock.offsetWidth;

            for(let i = 0; i < contentBlock.length; i++) {
              contentBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }

            for(let i = 0; i < footerBlock.length; i++) {
              footerBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }
          }

        }

        function periodSelection() {
          const allSelectionsMenu = document.getElementsByClassName("period-selection");

          for(let i = 0; i < allSelectionsMenu.length; i++) {

            const selectionsMenuLi =
                allSelectionsMenu[i].getElementsByTagName("ul")[0].getElementsByTagName("li");

            for(let i = 0; i < selectionsMenuLi.length; i++) {
              let anchor = selectionsMenuLi[i];
              anchor.onclick = function () {
                for (let i = 0; i < selectionsMenuLi.length; i++) {
                  selectionsMenuLi[i].classList.remove("active");
                }
                anchor.classList.add("active");
              }
            }

          }
        }

        function calculateContentMarginTop() {
          const headerHeight = $('header').height();
          $('.blog').css('margin-top', headerHeight + 'px');
          $('.dashboard').css('margin-top', headerHeight + 'px');
          $('.single').css('margin-top', headerHeight + 'px');
        }
        window.onresize = calculateContentMarginTop;

        function addEventListenerForMenu() {
          $('.main-pages-right').children('nav').children('ul').children('li').each(function () {
            $(this).on('click', function () {
              if ($('#homepage_hamburger_menu').css('display') === 'block') {
                $('.simple-hamburger').toggleClass('is-active');
                $('.header-inner .right').toggleClass('open');
                $('body').toggleClass('overflow');
              }
            });
          });
        }

    },
}
</script>

<style>
.hover:hover{
    cursor: pointer;
}

</style>
