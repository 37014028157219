<template>
    <div class="dash-sidebar-menu" id="dash-navigation">
      <div class="dash-nav">
        <div class="websites">
          <h2>Customers</h2>
          <div class="add-website">
              <router-link :to="{ name: 'AddWebsite' }" class="add-web"><img src="/img/dashboard/add_active.svg" alt="New Customer">New</router-link>
          </div>
          <form @submit="customers">
               <div class="form-group" style="margin-top: 10px;margin-left: 20px;">
                   <div style="padding-top: 5px">
                       <input v-model="customerEmail" type="text" maxlength="50" placeholder="E-mail" />
                   </div>
                   <div style="padding-top: 5px">
                       <input v-model="customerCompany" type="text" maxlength="50" placeholder="Company" />
                   </div>
                   <div style="padding-top: 5px">
                       <input v-model="customerName" type="text" maxlength="50" placeholder="Name" />
                   </div>
                   <div style="padding-top: 5px">
                       <input v-model="customerWebsite" type="text" maxlength="50" placeholder="Website" />
                   </div>
                   <div style="padding-top: 5px">
                       <input type="submit" class="next-step" value="Submit">
                   </div>
               </div>
          </form>
        </div>
        <div class="websites">
          <h2>Traffic</h2>
          <form @submit="traffic">
              <div class="form-group" style="margin-top: 10px;margin-left: 20px;">
                  <div style="padding-top: 5px">
                      <input v-model="trafficCampaign" type="text" maxlength="50" placeholder="UTM Campaign" />
                   </div>
                  <div style="padding-top: 5px">
                      <input v-model="trafficSource" type="text" maxlength="50" placeholder="UTM Source" />
                   </div>
                  <div style="padding-top: 5px">
                      <input v-model="trafficTerm" type="text" maxlength="50" placeholder="UTM Term" />
                   </div>
                  <div style="padding-top: 5px">
                      <input v-model="trafficWebsite" type="text" maxlength="50" placeholder="Website" />
                   </div>
                  <div style="padding-top: 5px">
                      <datepicker v-model="trafficStartDate" name="trafficStartDate" placeholder="Date From"></datepicker>
                   </div>
                  <div style="padding-top: 5px">
                      <datepicker v-model="trafficEndDate" name="trafficEndDate" placeholder="Date To"></datepicker>
                   </div>
                  <div style="padding-top: 5px">
                      <input type="submit" class="next-step" value="Submit">
                   </div>
              </div>
          </form>
        </div>
        <div class="websites">
          <h2>Fraud</h2>
          <form @submit="fraud">
              <div class="form-group" style="margin-top: 10px;margin-left: 20px;">
                  <div style="padding-top: 5px">
                      <input v-model="fraudCampaign" type="text" maxlength="50" placeholder="UTM Campaign" />
                   </div>
                  <div style="padding-top: 5px">
                      <input v-model="fraudSource" type="text" maxlength="50" placeholder="UTM Source" />
                   </div>
                  <div style="padding-top: 5px">
                      <input v-model="fraudTerm" type="text" maxlength="50" placeholder="UTM Term" />
                   </div>
                  <div style="padding-top: 5px">
                      <input v-model="fraudWebsite" type="text" maxlength="50" placeholder="Website" />
                   </div>
                  <div style="padding-top: 5px">
                      <input v-model="fraudReason" type="text" maxlength="50" placeholder="Reason" />
                   </div>
                   <div style="padding-top: 5px">
                       <datepicker v-model="fraudStartDate" name="fraudStartDate" placeholder="Date From"></datepicker>
                    </div>
                   <div style="padding-top: 5px">
                       <datepicker v-model="fraudEndDate" name="fraudEndDate" placeholder="Date To"></datepicker>
                    </div>
                  <div style="padding-top: 5px">
                      <input type="submit" class="next-step" value="Submit">
                   </div>
              </div>
          </form>
        </div>
        <div class="websites">
          <h2>Administrators</h2>
          <div class="add-website">
              <router-link :to="{ name: 'AddWebsite' }" class="add-web"><img src="/img/dashboard/add_active.svg" alt="New Administrator">New</router-link>
          </div>
          <ul class="website-list">
              <li>List All</li>
          </ul>
        </div>
        <div class="profile-nav-menu nav-menu">
          <ul>
            <li>
                <Logout />
            </li>
          </ul>
        </div>
      </div>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Logout from '@/components/authentication/Logout.vue';

export default {
    name: 'Sidebar',
    data() {
        return {
            customerEmail: "",
            customerCompany: "",
            customerName: "",
            customerWebsite: "",
            trafficCampaign: "",
            trafficSource: "",
            trafficTerm: "",
            trafficWebsite: "",
            trafficStartDate: "",
            trafficEndDate: "",
            fraudCampaign: "",
            fraudSource: "",
            fraudTerm: "",
            fraudWebsite: "",
            fraudReason: "",
            fraudStartDate: "",
            fraudEndDate: "",
        }
    },
    components: {
            Datepicker,
            Logout
    },
    methods: {
        async customers(evt) {

            evt.preventDefault();

            console.log(this.customerEmail, this.customerCompany, this.customerName, this.customerWebsite);

            /*
            let result = null;

            try {
                result = await this.$http.post('search/customers', {
                    customerEmail,
                    customerCompany,
                    customerName,
                    customerWebsite
                });

                return this.$router.push({
                    name: 'CustomerList',
                    params: { result: result.data.result }
                });

            } catch(error) {
                console.log(error);
            }
            */
        },
        async traffic(evt) {

            evt.preventDefault();

            console.log(this.trafficCampaign, this.trafficSource, this.trafficTerm, this.trafficWebsite, this.trafficStartDate, this.trafficEndDate);
        },
        async fraud(evt) {

            evt.preventDefault();

            console.log(this.fraudCampaign, this.fraudSource, this.fraudTerm, this.fraudWebsite, this.fraudReason, this.fraudStartDate, this.fraudEndDate);
        }
    },
    watch: {
        updateSidebar() {
            this.getWebsites();
        }
    }
}
</script>

<style>

#dash-navigation > div > div > form > div > input[type="text"],
#dash-navigation > div > div:nth-child(2) > input[type="text"] {
    width: 120px;
}

.website-list li {
    color: #fff;
    margin: 4px 8px;
}


.website-list li:hover, .reports-menu li:hover {
    cursor: pointer;
}

.selected {
    color: #030015 !important;
    margin: 4px 8px;
    border-radius: 6px;
    border: 0px solid #161F32;
    background-color: #03ADEE;
}

.reports-menu li.router-link-active,
.reports-menu li.router-link-exact-active {
     color: #030015 !important;
     margin: 4px 8px;
     border-radius: 6px;
     border: 0px solid #161F32;
     background-color: #03ADEE;
}

</style>
