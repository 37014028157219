<template>
  <div>
      <Header />
      <div class="dash-overflow"></div>
      <div class="dashboard">
        <Sidebar :reports="false" :addWeb="hasSub" :webButtonActive="false" :googleButtonActive="false" :settingsButtonActive="true" />

        <div class="dash-content">
            <h1 class="ttl">Settings</h1>

            <div class="navigation-block">
              <div>
                <router-link :to="{ name: 'Settings' }">Account</router-link>
              </div>
              <div class="active">
                <router-link :to="{ name: 'Subscription' }">Subscription</router-link>
              </div>
            </div>

            <div v-if="ready" class="dash-inner-content">

              <h1 class="ttl">Subscription</h1>

              <div v-for="subscription in customerSubscriptions" :key="subscription.id">
                  <div v-if="subscription.status == 'active'" class="settings-block mb-32">
                    <div class="settings-block-inner">

                      <form action="" class="settings-block-form padding-both-8">

                        <div class="settings-form-group padding-top-bottom-8">

                          <div class="settings-form-column">
                            <div class="settings-block-small-content blue-text">
                              Current Plan
                            </div>
                            <div v-if="subscription.name == '500'" class="settings-block-large-content">
                              Free Plan
                            </div>
                            <div v-if="subscription.name != '500'" class="settings-block-large-content">
                              Paid Plan
                            </div>
                            <div v-if="subscription.name != '500'" class="settings-block-middle-content gray-text">
                              Up to {{ subscription.name }} ad clicks<br>{{ price(subscription.name) }}
                            </div>
                          </div>

                          <div class="settings-form-column flex-end">
                            <div>
                              <span @click="upgrade(subscription)" class="control-button approve">Upgrade Plan</span>
                            </div>
                            <div v-if="subscription.name != '500'" class="padding-left-8">
                              <span @click="cancelSubscription" class="control-button cancel">Cancel Plan</span>
                            </div>
                          </div>

                        </div>

                        <div v-if="subscription.name != '500'" class="settings-form-group border-top-gray padding-top-bottom-8">

                          <div class="settings-form-column">
                            <div class="hint">
                              Your plan will renew on {{ expiry(subscription.expiry) }}
                            </div>
                          </div>

                        </div>

                      </form>

                    </div>
                  </div>

                  <div v-if="subscription.status != 'active'" class="settings-block mb-32">
                    <div class="settings-block-inner">

                        <div class="settings-form-group padding-top-bottom-8">

                          <div class="settings-form-column">
                            <div class="settings-block-small-content">
                              Cancelled
                            </div>
                            <div v-if="subscription.name == '500'" class="settings-block-large-content">
                              Free Plan
                            </div>
                            <div v-if="subscription.name != '500'" class="settings-block-large-content">
                              Paid Plan
                            </div>
                            <div v-if="subscription.name != '500'" class="settings-block-middle-content gray-text">
                              {{ price(subscription.name) }}
                            </div>
                          </div>

                        </div>

                        <div class="settings-form-group border-top-gray padding-top-bottom-8">

                          <div class="settings-form-column">
                            <div v-if="new Date(subscription.expiry) > new Date()" class="hint">
                              Can be used until {{ expiry(subscription.expiry) }}
                            </div>
                            <div v-else class="hint">
                              Canceled on  {{ expiry(subscription.expiry) }}
                            </div>
                          </div>

                        </div>

                    </div>
                  </div>
              </div>

              <div v-if="invoices" class="mb-32">
                  <h1 class="ttl">Invoices</h1>
                  <table>
                      <tr>
                          <th>Date</th>
                          <th>Amount</th>
                          <th>Invoice &amp; Receipt</th>
                      </tr>
                      <tr v-for="invoice in invoices" :key="invoice.id">
                          <td>{{ expiry(new Date(invoice.created * 1000).toISOString().substring(0,10)) }}</td>
                          <td>USD {{ invoice.amount_paid/100 }}</td>
                          <td><a :href="invoice.hosted_invoice_url" target="_new">Download</a></td>
                      </tr>
                  </table>
              </div>
            </div>

            <div v-if="ready && card && card.last4">
              <h1 class="ttl">Payment Method</h1>

              <div class="settings-block mb-16">
                <div class="settings-block-inner">

                    <div class="settings-form-group">

                      <div class="settings-form-column flex-start">
                        <div class="margin-both-8">
                          &bull;&bull;&bull;&bull;{{ card.last4 }}
                        </div>
                        <div class="margin-left-8 default-paid-plan">
                          Default
                        </div>
                      </div>

                      <div class="settings-form-column flex-center">
                        <div>
                          Expires {{ card.month }}/{{ card.year }}
                        </div>
                      </div>
                    </div>
                </div>
              </div>

              <div class="add-payment">
                <a @click="submitCardChange" href="#" class="add-new dashed">
                  <div class="text-field">
                    Change Credit Card
                  </div>
               </a>
              </div>

            </div>


        </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/dashboard/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';
import $ from 'jquery';

export default {
    name: 'Settings',
    data() {
        return {
            customerSubscriptions: [],
            ready: false,
            error: null,
            errors: null,
            card: null,
            subStatus: false,
            invoices: null
        }
    },
    components: {
        Header,
        Sidebar
    },
    async created() {

        // this is from Websites, should move to shared code
        // need this as he may cancel his existing sub so need to redirect him
        // check his subscription
        try {
            let response = await this.$http.get('/billing/subscription/status');

            this.$store.dispatch('setPaid', response.data.result.paid == true);

            if (response.data.result.expired && this.clicks) {
                // no active subscription
                this.subStatus = false;

                if (this.clicks == "500") {
                    let url = '/billing/subscription/create';

                    try {
                        await this.$http.post(url,
                            {
                                price_id: 'price_free',
                                product_id: 'prod_free',
                            }
                        );

                    } catch(error) {
                        this.error = error.response.data.error;
                    }

                } else {
                    return this.$router.push({
                        name: 'FirstSubscription',
                        params: { clicks: this.clicks }
                    });
                }

            } else if (response.data.result.expired) {
                // we don't know their clicks
                // this is because register -> e-mail -> close browser -> login, so we lost his clicks variable
                // need to fetch it from database

                this.subStatus = false;

                try {
                    let response = await this.$http.get('/authentication/package');
                    this.clicks = response.data.clicks.clicks;

                    return this.$router.push({
                        name: 'FirstSubscription',
                        params: { clicks: this.clicks }
                    });

                } catch(error) {
                    this.error = error;
                }
            } else {
                this.subStatus = true;
            }
        } catch(error) {
            this.error = error;
        }

        try {
            let response = await this.$http.get('/billing/subscriptions');
            this.customerSubscriptions = response.data.result;
        } catch(error) {
            this.error = error.response.data.error;
        }

        try {
            let response = await this.$http.get('/billing/card');
            this.card = response.data.result;
        } catch(error) {
            this.error = error.response.data.error;
        }

        try {
            let response = await this.$http.get('/billing/invoices');
            this.invoices = response.data.result;
        } catch(error) {
            this.error = error.response.data.error;
        }

        if (this.$route.params.reload && this.$route.params.reload == true) {
            location.reload();
        }

        this.ready = true;
    },
    computed: {
        create() {
            return this.customerSubscription.created_at.substring(0,10);
        },
        hasSub() {
            return this.subStatus;
        },
    },
    methods: {
        price(clicks) {

            let value = null;

            switch(clicks) {
                case '10,000':
                    value = '$50.00 per month';
                    break;
                case '25,000':
                    value = '$75.00 per month';
                    break;
                case '50,000':
                    value = '$100.00 per month';
                    break;
                case '75,000':
                    value = '$125.00 per month';
                    break;
                case '100,000':
                    value = '$150.00 per month';
                    break;
            }

            return value;
        },
        expiry(sub) {

            let ts = new Date(sub);
            ts = ts.toISOString();
            ts = ts.replace("T", " ");
            ts = ts.replace(/\..*/, "");
            ts = ts.toString();
            const t = ts.split(/[- :]/);
            ts = new Date(Date.UTC(ts[0], ts[1]-1, ts[2], ts[3], ts[4], ts[5]));

            const d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
            const options = {
                year:
                'numeric',
                month: 'long',
                day: 'numeric',
                //timeZone: 'UTC'
            };

            return(d.toLocaleDateString('en-US', options));

        },
        async cancelSubscription() {
            try {
                //const response = await this.$http.get('/billing/subscription/cancel');
                await this.$http.get('/billing/subscription/cancel');
                this.$router.go();
            } catch(error) {
                this.error = error.response.data.error;
            }
        },
        upgrade(subscription) {
            this.$router.push({
                name: 'Upgrade',
                params: {
                    clicks: subscription.name
                }
            });
        },
        submitCardChange(evt) {

            evt.preventDefault();

            this.$router.push({
                name: 'NewCard',
            });
        }
    },
    mounted() {

        $( document ).ready(function() {

          $('.simple-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.header-inner .right').toggleClass('open');
            $('body').toggleClass('overflow');
          });

          $('.dash-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.dash-nav').toggleClass('open');
            $('body').toggleClass('overflow');
            $('.dash-overflow').toggleClass('visible');
            $('.dash-reports').toggleClass('open');
          });

          $('.dash-overflow').on('click', function(){
            $('.dash-hamburger').removeClass('is-active');
            $('.dash-nav').removeClass('open');
            $('body').removeClass('overflow');
            $(this).removeClass('visible');
            $('.dash-reports').removeClass('open');
          });

          closeContentBlock();
          openLoginMenu();
          calcMainContentMargin();
          periodSelection();
          calculateContentMarginTop();
          addEventListenerForMenu();

        });

        function closeContentBlock() {
          const closeButtons = document.getElementsByClassName("close");

          for(let i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener("click", function (){
              closeButtons[i].parentElement.style.display = "none";
            });
          }
        }

        function openLoginMenu() {
          const accountMenu = document.getElementById("account-menu");
          const accountMenuPopup = document.getElementById("account-menu-popup");

          if (null !== accountMenu && null !== accountMenuPopup) {
            const toggleMenu = function() {
              accountMenuPopup.classList.toggle("hide-element");
            }

            accountMenu.addEventListener("click", function (e) {
              e.stopPropagation();
              toggleMenu();
            });

            document.addEventListener("click", function(e){
              const target = e.target;
              const itsAccountMenuPopup = target === accountMenuPopup || accountMenuPopup.contains(target);
              const itsAccountMenu = target === accountMenu;
              const menuIsActive = !accountMenuPopup.classList.contains("hide-element");

              if (!itsAccountMenuPopup && !itsAccountMenu && menuIsActive) {
                toggleMenu();
              }
            });
          }

        }

        function toggleDropdown() {
          $('.dropdown').on('click', function(){
            $('.dropdown-active + ul').slideToggle()
            let content = $('.dropdown-active + ul li.active a').text()
            $('.dropdown-active').html(content)
          })
        }
        toggleDropdown()

        function calcMainContentMargin() {
          const navigationBlock = document.getElementById("dash-navigation");
          const contentBlock = document.getElementsByClassName("dash-content");
          const footerBlock = document.getElementsByTagName("footer");

          if (
              null !== navigationBlock &&
              null !== contentBlock &&
              null !== footerBlock
          ) {
            const navigationBlockWidth = navigationBlock.offsetWidth;

            for(let i = 0; i < contentBlock.length; i++) {
              contentBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }

            for(let i = 0; i < footerBlock.length; i++) {
              footerBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }
          }

        }

        function periodSelection() {
          const allSelectionsMenu = document.getElementsByClassName("period-selection");

          for(let i = 0; i < allSelectionsMenu.length; i++) {

            const selectionsMenuLi =
                allSelectionsMenu[i].getElementsByTagName("ul")[0].getElementsByTagName("li");

            for(let i = 0; i < selectionsMenuLi.length; i++) {
              let anchor = selectionsMenuLi[i];
              anchor.onclick = function () {
                for (let i = 0; i < selectionsMenuLi.length; i++) {
                  selectionsMenuLi[i].classList.remove("active");
                }
                anchor.classList.add("active");
              }
            }

          }
        }

        function calculateContentMarginTop() {
          const headerHeight = $('header').height();
          $('.blog').css('margin-top', headerHeight + 'px');
          $('.dashboard').css('margin-top', headerHeight + 'px');
          $('.single').css('margin-top', headerHeight + 'px');
        }
        window.onresize = calculateContentMarginTop;

        function addEventListenerForMenu() {
          $('.main-pages-right').children('nav').children('ul').children('li').each(function () {
            $(this).on('click', function () {
              if ($('#homepage_hamburger_menu').css('display') === 'block') {
                $('.simple-hamburger').toggleClass('is-active');
                $('.header-inner .right').toggleClass('open');
                $('body').toggleClass('overflow');
              }
            });
          });
        }

    }
}
</script>

<style scoped>

    table {
        border: 1px solid #2B3446;
        border-radius: 12px;
        background-color: #161F32;
        width: 100%;
        max-width: 575px;
    }

    th, td {
        color: #fff;
        border: 0px dotted #dddddd;
        text-align: left;
        padding: 8px 16px;
    }

    td a {
        color: #fff;
    }

</style>
