<template>
  <div>
      <Header />
      <div class="dash-overflow"></div>
      <div class="dashboard dashboard-keywords">
        <Sidebar :reports="false" :addWeb="hasSub" :websitesButton="false" />
        <div class="dash-content">
            <h1 class="ttl">Settings</h1>

            <div class="navigation-block">
              <div>
                <router-link :to="{ name: 'Settings' }">Account</router-link>
              </div>
              <div class="active">
                <router-link :to="{ name: 'Subscription' }">Subscription</router-link>
              </div>
            </div>

            <div>
              <ul class="breadcrumb">
                <li><router-link :to="{ name: 'Subscription' }">Subscription</router-link></li>
                <li><router-link :to="{ name: 'Upgrade' }">Upgrade</router-link></li>
                <li>Payment</li>
              </ul>
            </div>

            <div class="dash-inner-content">

                <form @submit="submitForm">
                  <div class="settings-block mb-16" style="padding: 12px;">
                    <div class="settings-block-inner">
                      <div class="settings-block-form">
                        <div class="settings-form-group payment-card-details">
                          <div class="settings-form-column flex-start width-100-percent">
                            <div class="margin-both-8 width-100-percent">
                              <input v-model="form.fullName" type="text" id="name" placeholder="Name on credit card" class="input-card-details">
                            </div>
                          </div>
                        </div>
                        <span v-if="formErrors.fullNameEmpty" class="error">Name is required.</span>
                        <span v-if="formErrors.fullNameTooLong" class="error">Name must be shorter than 255 characters.</span>
                      </div>
                    </div>
                  </div>

                  <div class="settings-block mb-16" style="padding: 12px;">
                    <div class="settings-block-inner">
                      <div class="settings-block-form">
                        <div class="settings-form-group payment-card-details">
                          <div class="settings-form-column flex-start width-100-percent">
                            <div class="margin-both-8 width-100-percent">
                              <input v-model="form.email" type="email" id="email" placeholder="E-mail address (for invoices)" class="input-card-details">
                            </div>
                          </div>
                        </div>
                        <span v-if="formErrors.emailEmpty" class="error">E-mail is required.</span>
                        <span v-if="formErrors.emailInvalid" class="error">Invalid e-mail address.</span>
                        <span v-if="formErrors.emailTooLong" class="error">E-mail must be shorter than 50 characters.</span>
                      </div>
                    </div>
                  </div>

                  <div class="settings-block mb-16" style="padding: 12px;">
                    <div class="settings-block-inner">
                      <div class="settings-block-form">
                        <div class="settings-form-group payment-card-details credit-card">

                          <div class="settings-form-column flex-start m-width-200">
                            <div v-if="ready" class="margin-both-8 width-100-percent">
                              <div id="card-element" class="input-card-details"></div>
                            </div>
                            <div v-else class="margin-both-8 width-100-percent">
                              Loading...
                            </div>
                          </div>

                        </div>
                        <span v-if="formErrors.card" class="error">{{ formErrors.card }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="add-payment mb-16">
                      <button :disabled="sending" class="block">{{ buttonText }}</button>
                  </div>

                </form>

            </div>
        </div>
      </div>
  </div>
</template>

<script>
import Header from '@/components/dashboard/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';
import $ from 'jquery';

export default {
    name: 'Payment',
    data() {
        return {
            buttonText: "Submit Payment",
            client: {
                email: null,
                key: null,
                secret: null,
                price: null,
                product: null
            },
            form: {
                fullName: '',
                email: ''
            },
            formErrors: {
                fullNameEmpty: false,
                fullNameTooLong: false,
                emailEmpty: false,
                emailInvalid: false,
                emailTooLong: false,
                card: false
            },
            stripe: '',
            cardElement: '',
            error: null,
            ready: false,
            subStatus: false,
            sending: false
        }
    },
    components: {
        Header,
        Sidebar
    },
    async created() {

        this.client.email = this.$route.params.email;
        this.client.key = this.$route.params.key;
        this.client.secret = this.$route.params.secret;
        this.client.price = this.$route.params.price;
        this.client.product = this.$route.params.product;
        this.client.customer = this.$route.params.customer;
        this.client.subscription = this.$route.params.subscription;

        this.form.email = this.client.email;

        // check if he has a sub
        try {
            let response = await this.$http.get('/billing/subscription/status');

            this.$store.dispatch('setPaid', response.data.result.paid == true);

            if (response.data.result.expired && this.clicks) {
                // no active subscription
                this.subStatus = false;

            } else if (response.data.result.expired) {

                this.subStatus = false;

            } else {
                this.subStatus = true;
            }
        } catch(error) {
            this.error = error;
        }

        if (!Object.keys(this.$route.params).length) {
            return this.$router.push({
                name: 'Upgrade',
            });
        }

    },
    methods: {
        async submitForm(evt) {

            evt.preventDefault();

            if (this.sending == true) return;

            this.sending = true;

            this.clearErrors();

            this.formErrors = this.validateForm(this.form);

            // if formErrors is not empty
            if (Object.keys(this.formErrors).length) {
                this.sending = false;
                return;
            }

            this.buttonText = "Please wait...";

            // everything ok
            // create payment method and confirm payment intent
            try {
                const result = await this.stripe.confirmCardPayment(this.client.secret,
                    {
                        payment_method: {
                            card: this.cardElement,
                            billing_details: {
                                name: this.form.fullName,
                            },
                        },
                        receipt_email: this.form.email
                    }
                );
                if (result.error) {
                    this.sending = false;
                    this.buttonText = "Submit Payment";
                    this.formErrors.card = result.error.message;
                } else {
                    this.processResult(result);
                }

            } catch (error) {
                this.error = error.message;
            }
        },
        async processResult(evt) {

            try {
                await this.$http.post('/billing/subscription/card', {
                    paymentIntentId: evt.paymentIntent.id,
                    paymentMethod: evt.paymentIntent.payment_method,
                    price: this.client.price,
                    product: this.client.product,
                    customer: this.client.customer,
                    subscription: this.client.subscription
                });

                setTimeout(() => {
                    return this.$router.push({
                        name: 'Subscription',
                        params: { 'reload': true }
                    });
                }, 1500);

            } catch(error) {
                this.error = error.response.data.error;
            }
        },
        clearErrors() {

            this.error = null;
            this.formErrors.fullNameEmpty = false;
            this.formErrors.fullNameTooLong = false;
            this.formErrors.emailEmpty = false;
            this.formErrors.emailInvalid = false;
            this.formErrors.emailTooLong = false;
            this.formErrors.card = false;

        },
        validateForm(form) {

            // check the entire form in one go rather than updating formErrors one by one
            const errors = {};

            if (!form.fullName)
              errors.fullNameEmpty = true;

            if (form.fullName.length > 255)
              errors.fullNameTooLong = true;

            if (!form.email)
              errors.emailEmpty = true;

            if (form.email.length > 50)
              errors.emailTooLong = true;

            if (form.email && !(/^\S+@\S+\.\S+$/.test(form.email)))
              errors.emailInvalid = true;

            return errors;
        }
    },
    watch: {
        'form.fullName'() {
            if (this.formErrors.fullNameEmpty || this.formErrors.fullNameTooLong) {
              this.formErrors.fullNameEmpty = false;
              this.formErrors.fullNameTooLong = false;
            }
        },
        'form.email'() {
            if (this.formErrors.emailEmpty || this.formErrors.emailInvalid || this.formErrors.emailTooLong) {
              this.formErrors.emailEmpty = false;
              this.formErrors.emailInvalid = false;
              this.formErrors.emailTooLong = false;
            }
        },
    },
    computed: {
        hasSub() {
            return this.subStatus;
        },
    },
    mounted() {
        $( document ).ready(function() {

          $('.simple-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.header-inner .right').toggleClass('open');
            $('body').toggleClass('overflow');
          });

          $('.dash-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.dash-nav').toggleClass('open');
            $('body').toggleClass('overflow');
            $('.dash-overflow').toggleClass('visible');
          });

          $('.dash-overflow').on('click', function(){
            $('.dash-hamburger').removeClass('is-active');
            $('.dash-nav').removeClass('open');
            $('body').removeClass('overflow');
            $(this).removeClass('visible');
          });

          closeContentBlock();
          openLoginMenu();
          calcMainContentMargin();
          periodSelection();
          calculateContentMarginTop();
          addEventListenerForMenu();
        });

        function closeContentBlock() {
          const closeButtons = document.getElementsByClassName("close");

          for(let i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener("click", function (){
              closeButtons[i].parentElement.style.display = "none";
            });
          }
        }

        function openLoginMenu() {
          const accountMenu = document.getElementById("account-menu");
          const accountMenuPopup = document.getElementById("account-menu-popup");

          if (null !== accountMenu && null !== accountMenuPopup) {
            const toggleMenu = function() {
              accountMenuPopup.classList.toggle("hide-element");
            }

            accountMenu.addEventListener("click", function (e) {
              e.stopPropagation();
              toggleMenu();
            });

            document.addEventListener("click", function(e){
              const target = e.target;
              const itsAccountMenuPopup = target === accountMenuPopup || accountMenuPopup.contains(target);
              const itsAccountMenu = target === accountMenu;
              const menuIsActive = !accountMenuPopup.classList.contains("hide-element");

              if (!itsAccountMenuPopup && !itsAccountMenu && menuIsActive) {
                toggleMenu();
              }
            });
          }

        }

        function toggleDropdown() {
          $('.dropdown').on('click', function(){
            $('.dropdown-active + ul').slideToggle()
            let content = $('.dropdown-active + ul li.active a').text()
            $('.dropdown-active').html(content)
          })
        }
        toggleDropdown()

        function calcMainContentMargin() {
          const navigationBlock = document.getElementById("dash-navigation");
          const contentBlock = document.getElementsByClassName("dash-content");
          const footerBlock = document.getElementsByTagName("footer");

          if (
              null !== navigationBlock &&
              null !== contentBlock &&
              null !== footerBlock
          ) {
            const navigationBlockWidth = navigationBlock.offsetWidth;

            for(let i = 0; i < contentBlock.length; i++) {
              contentBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }

            for(let i = 0; i < footerBlock.length; i++) {
              footerBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }
          }

        }

        function periodSelection() {
          const allSelectionsMenu = document.getElementsByClassName("period-selection");

          for(let i = 0; i < allSelectionsMenu.length; i++) {

            const selectionsMenuLi =
                allSelectionsMenu[i].getElementsByTagName("ul")[0].getElementsByTagName("li");

            for(let i = 0; i < selectionsMenuLi.length; i++) {
              let anchor = selectionsMenuLi[i];
              anchor.onclick = function () {
                for (let i = 0; i < selectionsMenuLi.length; i++) {
                  selectionsMenuLi[i].classList.remove("active");
                }
                anchor.classList.add("active");
              }
            }

          }
        }

        function calculateContentMarginTop() {
          const headerHeight = $('header').height();
          $('.blog').css('margin-top', headerHeight + 'px');
          $('.dashboard').css('margin-top', headerHeight + 'px');
          $('.single').css('margin-top', headerHeight + 'px');
        }
        window.onresize = calculateContentMarginTop;

        function addEventListenerForMenu() {
          $('.main-pages-right').children('nav').children('ul').children('li').each(function () {
            $(this).on('click', function () {
              if ($('#homepage_hamburger_menu').css('display') === 'block') {
                $('.simple-hamburger').toggleClass('is-active');
                $('.header-inner .right').toggleClass('open');
                $('body').toggleClass('overflow');
              }
            });
          });
        }

        // give things a moment
        setTimeout(() => {

            this.stripe = window.Stripe(this.client.key);

            const elements = this.stripe.elements();

            let style = {
              base: {
                  fontFamily: "'Inter', sans-serif",
                  color: '#fff',
                  fontSize: '14px',
                  fontWeight: '400',
                  backgroundColor: '#161F32',
                  '::placeholder': {
                    color: '#fff',
                  },
                  ':-webkit-autofill': {
                    color: '#fff',
                  },
              },
            };

            this.cardElement = elements.create('card', {
              style: style
            });
            this.cardElement.mount('#card-element');

            this.ready = true;

        }, 3000);

    }
}
</script>

<style>
    .error, .error-large {
        margin: 2px 0 0 0 !important;
        color: #FF7F7F !important;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        padding: 0px 8px;
    }
    .error-large {
        font-size: large;
        text-align: center;
    }
    .block {
        margin: 0 auto;
        align-items: center;
        border: 1px solid #2B3446;
        border-radius: 12px;
        background-color: #03ADEE;
        height: 50px;
        width: 100%;
        max-width: 575px;
        cursor: pointer;
        font-weight: 800;
        font-size: 20px;
        line-height: 28px;
        color: #ffff;
    }
    .block:hover {
        background-color: #008BD0;
        transition: 0.3s ease-in-out;
    }
</style>
