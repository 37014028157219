<template>
    <footer>
    <div class="footer-inner">

      <div class="footer-bottom">
        <div class="copy">
          &copy; Polygraph {{ year }}
        </div>

      </div>
    </div>
    </footer>
</template>

<script>

export default {
  name: 'Footer',
  computed: {
      year() {
          return new Date().getFullYear();
      }
  },
}
</script>
